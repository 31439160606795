import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import ConversationMessage from './ConversationMessage';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    maxHeight: '100%'
  },
  inner: {
    padding: theme.spacing(2),
  }
}));

function ConversationMessages({ messages, className, ...rest }) {
  const classes = useStyles();
  const [height, setHeight] = React.useState(0);
  const [styles, setStyles] = useState({targetDiv: { height: 'calc(100vh - 200px)'}})
  const [messagesList, setMessagesList] = useState([]);
  const refContainer = useRef();


  useEffect(() => {
    if (messages.length > 0 && !isEqual(messagesList, messages)) {
      setMessagesList(messages);

      if (refContainer.current && messages.length > 1) {
        refContainer.current['_container'].scrollTop = refContainer.current['_container'].scrollHeight;
      } else if (refContainer.current && messages.length <= 1) {
        refContainer.current['_container'].scrollTop = 0
        setStyles({targetDiv: { height: '100%'}})
      }
    }
  }, [messagesList, messages]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <PerfectScrollbar ref={refContainer}>
        <div className={classes.inner} style={styles.targetDiv} >
          {messages.map((message) => {
            return (
              <ConversationMessage
                key={message.id}
                message={message} //
              />
            );
          })}
        </div>
      </PerfectScrollbar>
    </div>
  );
}

ConversationMessages.propTypes = {
  className: PropTypes.string,
  messages: PropTypes.array.isRequired
};

export default ConversationMessages;
