/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  // Badge,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
  Hidden,
  colors,
  Tabs,
  Tab,
  Menu,
  DialogActions,
  DialogContent,
  Dialog,
  List,
  ListItem,
  TextField,
  // Popper,
  // Paper,
  // List,
  // ListItem,
  // ListItemIcon,
  // ListItemText,
  // Tooltip,
  // ClickAwayListener,
  // Typography,
  Avatar,
  Typography,
  Box,
  ButtonBase,
  // Link,
  Switch
} from '@material-ui/core';
// import LockIcon from '@material-ui/icons/LockOutlined';
// import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
// import PeopleIcon from '@material-ui/icons/PeopleOutline';
// import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
// import SearchIcon from '@material-ui/icons/Search';
import NotificationsPopover from 'src/componentsnew/NotificationsPopover';
import PricingModal from 'src/componentsnew/PricingModal';
import { logout, login, } from 'src/actions';
// import ChatBar from './ChatBar';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
// import SwitchDirectionModal from './SwitchDirectionModal';
// import Cookies from 'js-cookie';
import jwt from 'jwt-decode';
import axios from 'axios';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';
// import getInitials from 'src/utils/getInitials';
// import ColorLuminance from 'src/utils/colorLuminance'
import navColors from './navColors';

// let primaryMain;
// if(!localStorage.getItem("TenantDetail")) {
//   primaryMain = '#232c66'
// } else {
//   primaryMain = JSON.parse(localStorage.getItem("TenantDetail"))["topbar_color"] || JSON.parse(localStorage.getItem("TenantDetail"))["primary_color"];
// }


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: 'none',
    borderBottomStyle: 'groove'
    // backgroundColor: tenantDetail.topbar_color || theme.palette.primary.main,
  },
  flexGrow: {
    flexGrow: 1
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  ecosystemPick: {
    textTransform: 'unset !important',
    fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 15,
    marginLeft: 40,
    ['@media (max-width:1285px)']: {
      display: 'none',
     },
     ['@media (max-width: 944px)']: {
      display: 'block',
      },

    // color: topbarTextColor,
  },
  ecosystemPickBlack: {
    marginLeft: theme.spacing(2),
    // color: topbarTextColor,
    backgroundColor: theme.palette.primary.main,
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  // trialButton: {
  //   marginLeft: theme.spacing(2),
  //   backgroundColor: colors.green[800],
  //   '&:hover': {
  //     backgroundColor: colors.green[900]
  //   }
  // },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  chatButton: {
    marginLeft: theme.spacing(1)
  },
  notifications: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    with: '100%',

  },
  notificationsButton: {
    // color: topbarTextColor,
    paddingTop: 0,
    paddingBottom: 0,
    marginRight: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between",
    ['@media (max-width:700px)']: {
      marginRight: 25,
    },
    // marginLeft: theme.spacing(1)
  },
  notificationIcon: {
    transform: 'translateY(4px)'
  },
  notificationsBadge: {
    position: 'absolute',
    right: 0,
    // backgroundColor: theme.palette.primary.main,
    // border: '1px solid' + topbarTextColor,
    borderRadius: 10,
    width: 22,
    height: 15,
    fontSize: 8,
    transform: 'translate(10px, -20px)',
    display: 'grid',
    placeItems: 'center'

    // backgroundColor: colors.orange[600]
  },
  notificationDot: {
    position: 'absolute',
    bottom: 18,
    left: 16,
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: '#F2C94C'
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  // logoText: {
  //   marginRight: theme.spacing(1),
  //   // color: topbarTextColor
  // },
  avatar: {
    color: colors.grey[800],
    backgroundColor: colors.grey[100],
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200
  },
  modalIcon: {
    cursor: 'pointer'
  },
  names: {
    // color: topbarTextColor,
    marginRight: theme.spacing(1),
  },
  companyName: {
    // color: topbarTextColor,
    // backgroundColor: colors.grey[100],
  },
  toolbar: {
    minHeight: 40,
  },
  logo: {
    marginTop: 3,
    height: 33,
    ['@media (max-width:520px)']: {
      height: 'unset',
      width: 33
    },
  },
  navigationTop: {
    // marginLeft: '80px',
    position: 'absolute',
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    ['@media (max-width: 800px)']: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  menuLabel: {
    cursor: 'pointer',
    // color: topbarTextColor,
    textTransform: 'none',
    fontFamily: 'Gilroy',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 18,
    textTransform: 'capitalize',
    marginRight: theme.spacing(5),
    '&:hover': {
      fontSize: 18,
      opacity: 1,
      borderBottomStyle: 'ridge',
      borderWidth: 'medium',
    },
  
    // '&:focus': {
    //   fontSize: 20,
    //   opacity: 1,
    // },
  },
  active: {
    cursor: 'pointer',
    // color: topbarTextColor,
    textTransform: 'none',
    fontFamily: 'Gilroy',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 18,
    textTransform: 'capitalize',
    marginRight: theme.spacing(5),
    borderBottomStyle: 'ridge',
    borderWidth: 'medium',
    '&:hover': {
      fontSize: 18,
      opacity: 1,
    },
  },

}));

const popularSearches = [
  'Accounting',
  'Xero',
  'Admin Pannel',
  'Project',
  'Pages'
];

function TopBar({ onOpenNavBarMobile, ecosystems, handleEcosystemChange, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const searchRef = useRef(null);
  const dispatch = useDispatch();
  const notificationsRef = useRef(null);
  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openChatBar, setOpenChatBar] = useState(false);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const session = useSelector(state => state.session);
  const [openSwitchDirection, setOpenSwitchDirection] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [tenantDetail, setTenantDetail] = useState(
    localStorage.getItem("TenantDetail") ? JSON.parse(localStorage.getItem("TenantDetail")) : {});

  const [openMenu, setOpenMenu] = useState(false);
  const anchorRef = useRef(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleLogout = () => {
    // TODO - Delete all session data from local storage
    localStorage.removeItem('jwtToken');
    // localStorage.removeItem('TenantDetail');
    history.push('/auth/login');
    dispatch(logout());
  };

  const handlePricingModalOpen = () => {
    setPricingModalOpen(true);
  };

  const handlePricingModalClose = selection => {
    setPricingModalOpen(false);
  };

  const handleChatBarOpen = () => {
    setOpenChatBar(true);
  };

  const handleChatBarClose = () => {
    setOpenChatBar(false);
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleNotificationsMarkRead = () => {
    let mounted = true;
    const jwtToken = localStorage.getItem('jwtToken') || '';
    const axios = require('axios');

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}api/User/MarkAllNotificationsRead`,
      headers: {
        'Authorization': 'Bearer ' + jwtToken
      },
      // data: requestData
    }).then((response) => {
      if (mounted) {
        // history.push('/profile/incoming');
      }
    }, (error) => {
      console.log('MarkAllNotificationsRead error');
      console.log(error);
    })

    getNotifications();
    setOpenNotifications(false);

  };

  const handleSearchChange = event => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchPopover) {
        setOpenSearchPopover(true);
      }
    } else {
      setOpenSearchPopover(false);
    }
  };

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };

  const getNotifications = () => {
    const jwtToken = localStorage.getItem('jwtToken') || '';
    // Check the direction from URL and set the session value accordingly

    const axios = require('axios');
    let mounted = true;
    const fetchNotifications = () => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}api/User/GetUserNotifications`,
        headers: {
          Authorization: 'Bearer ' + jwtToken
        }
      }).then(
        response => {
          if (mounted) {
            setNotifications(response.data);

            // Update the logged in user details (redux loses values on refresh)
            const user = jwt(jwtToken); // decode your token here

            // Handeling if the JWT is an old version that doesn't contain required information. Forcing log out so that user will generate a new JWT
            if (!user.EcosystemSubdomain || !user.SubscriptionStatus) {
              localStorage.removeItem('jwtToken');
              console.log('Handeling if the JWT is an old version that does not contain required information. Forcing log out so that user will generate a new JWT');
              history.push('/auth/login');
            }


            dispatch(login(jwtToken, user));
          }
        },
        error => {
          console.log('notifications error');
          console.log(error);
          // localStorage.setItem('postLoginPath', history.location.pathname);
          // error.response && error.response.status === 401
          //   ? history.push('/auth/login')
          //   : window.alert(
          //     'Oooops... An error has occured. Please contact support@bizforce.io. GetUserNotifications' +
          //     error
          //   );
        }
      );
    };

    if (jwtToken !== '') {
      fetchNotifications();
    }

    return () => {
      mounted = false;
    };
  };

  useEffect(() => {
    let mounted = false;

    if (!mounted) {

      let subDomain = window.location.hostname.replace('.bizforce.io', '');
      axios.get(`${process.env.REACT_APP_API_URL}api/ecosystem/GetEcosystemDetails/` + subDomain)
        .then((res) => {
          if (res != null && res.data !== null && res.data.length > 0) {
            var tenantInfo = res.data[0];
            tenantInfo.subdomain = subDomain;
            localStorage.setItem("TenantDetail", JSON.stringify(res.data[0]));
            setTenantDetail(res.data[0]);
          }
        });
      mounted = true;

    }

    getNotifications();

    setActiveMenuLabel(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1));

  }, []);

  const unreadMsgsCount = notifications.filter(
    item => item['notification_status'] === 'Unread'
  ).length;

  const removeValuefromStorage = () => {
    localStorage.removeItem("MarketplaceGuide");
    localStorage.removeItem("PartnersGuide");
    localStorage.removeItem("CompanyProfileGuide");
    localStorage.removeItem("CompanyCommissionGuide");
    localStorage.removeItem("TagsGuide");
    localStorage.removeItem("BoardGuide");
  }

  // const params = useParams()
  const [activeMenuLabel, setActiveMenuLabel] = useState('feed')

  useEffect(() => {
    setActiveMenuLabel(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1));
  }, [window.location.pathname]);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}
      style={{ backgroundColor: tenantDetail.topbar_color || tenantDetail.primary_color }}
    >

      <Toolbar
        className={classes.toolbar}
      >

        {/* <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden> */}
        {/* <Hidden mdDown> */}
        <RouterLink style={{ zIndex: '100' }} to="/">

          {/* <Typography className={classes.logoText}
          variant="h2"
        >
            BizForce
          </Typography> */}
          <Hidden smDown>
            <img
              alt={tenantDetail.name}
              className={classes.logo}
              // src="/images/logos/bizforce-logo-white.svg"
              src={tenantDetail.logo}
            />
          </Hidden>
          <Hidden mdUp>
            <img
              alt={tenantDetail.name}
              className={classes.logo}
              // src="/images/logos/bizforce-logo-white.svg"
              src={tenantDetail.logo_icon || tenantDetail.logo}
            />
          </Hidden>
        </RouterLink>
        {/* </Hidden> */}
        <div className={classes.flexGrow} />
        <Dialog
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <List className={classes.list}>
              <ListItem button onClick={removeValuefromStorage}>
                Take the tour guide
              </ListItem>
            </List>
            <List className={classes.list}>
              <ListItem button component={RouterLink} to="/getting-started" onClick={() => setIsOpenModal(false)}>
                Getting started document
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsOpenModal(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>



        <Hidden smDown>

          <div className={classes.navigationTop}>

            <Typography
              className={activeMenuLabel === 'feed' ? classes.active : classes.menuLabel}
              style={{ color: tenantDetail.topbar_color ? tenantDetail.primary_color : '#fff' }}
              component={RouterLink}
              to='/marketplace/feed'
            >
              {localStorage.getItem("TenantDetail") && JSON.parse(localStorage.getItem("TenantDetail"))["tab_name_feed"] ? JSON.parse(localStorage.getItem("TenantDetail"))["tab_name_feed"] : 'feed'}
            </Typography>
            {localStorage.getItem("TenantDetail") && JSON.parse(localStorage.getItem("TenantDetail"))["tab_name_members"] &&
              <Typography
                className={activeMenuLabel === 'members' ? classes.active : classes.menuLabel}
                style={{ color: tenantDetail.topbar_color ? tenantDetail.primary_color : '#fff' }}
                component={RouterLink}
                to='/marketplace/members'
              >
                {localStorage.getItem("TenantDetail") && JSON.parse(localStorage.getItem("TenantDetail"))["tab_name_members"] ? JSON.parse(localStorage.getItem("TenantDetail"))["tab_name_members"] : 'members'}
              </Typography>}
              {localStorage.getItem("TenantDetail") && JSON.parse(localStorage.getItem("TenantDetail"))["tab_name_opportunities_board"] &&
            <Typography
              className={activeMenuLabel === 'projects' ? classes.active : classes.menuLabel}
              style={{ color: tenantDetail.topbar_color ? tenantDetail.primary_color : '#fff' }}
              component={RouterLink}
              to='/marketplace/projects'
            >
              {localStorage.getItem("TenantDetail") && JSON.parse(localStorage.getItem("TenantDetail"))["tab_name_opportunities_board"] ? JSON.parse(localStorage.getItem("TenantDetail"))["tab_name_opportunities_board"] : 'opportunities'}
              </Typography>}
            {localStorage.getItem("TenantDetail") && JSON.parse(localStorage.getItem("TenantDetail"))["tab_name_library"] &&
            <Typography
              className={activeMenuLabel === 'resources' ? classes.active : classes.menuLabel}
              style={{ color: tenantDetail.topbar_color ? tenantDetail.primary_color : '#fff' }}
              component={RouterLink}
              to='/marketplace/resources'
            >
              {localStorage.getItem("TenantDetail") && JSON.parse(localStorage.getItem("TenantDetail"))["tab_name_library"] ? JSON.parse(localStorage.getItem("TenantDetail"))["tab_name_library"] : 'resources'}
              </Typography>}
              {localStorage.getItem("TenantDetail") && JSON.parse(localStorage.getItem("TenantDetail"))["tab_name_events"] &&
            <Typography
              className={activeMenuLabel === 'events' ? classes.active : classes.menuLabel}
              style={{ color: tenantDetail.topbar_color ? tenantDetail.primary_color : '#fff' }}
              component={RouterLink}
              to='/marketplace/events'
            >
              {localStorage.getItem("TenantDetail") && JSON.parse(localStorage.getItem("TenantDetail"))["tab_name_events"] ? JSON.parse(localStorage.getItem("TenantDetail"))["tab_name_events"] : 'events'}
              </Typography>}

          </div>

        </Hidden>



        {ecosystems && ecosystems.length > 1 &&
          <>
            <Button
              className={classes.ecosystemPick}
              style={{ color: tenantDetail.topbar_color ? tenantDetail.primary_color : '#fff' }}
              aria-controls="simple-menu" aria-haspopup="true"
              ref={anchorRef}
              onClick={handleMenuOpen}
            >
              My Communities
            <ArrowDropDownIcon />
            </Button>
            <Menu
              anchorEl={anchorRef.current}
              id="simple-menu"
              className={classes.menu}
              keepMounted
              onClose={handleMenuClose}
              open={openMenu}
              elevation={1}
              PaperProps={{ style: { width: 250 } }}
            >
              {ecosystems.map((option) => (
                <MenuItem
                  data-ecosystem={option.subdomain}
                  className={classes.menuItem}
                  onClick={handleEcosystemChange}
                  key={option.subdomain}
                  value={option.subdomain}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Menu>
          </>}


        {/* <div className={classes.flexGrow} /> */}
        {/* <Hidden smDown> */}
        {/* <div
            className={classes.search}
            ref={searchRef}
          >
            <SearchIcon className={classes.searchIcon} />
            <Input
              className={classes.searchInput}
              disableUnderline
              onChange={handleSearchChange}
              placeholder="Search partners &amp; referrals"
              value={searchValue}
            />
          </div>
          <Popper
            anchorEl={searchRef.current}
            className={classes.searchPopper}
            open={openSearchPopover}
            transition
          >
            <ClickAwayListener onClickAway={handleSearchPopverClose}>
              <Paper
                className={classes.searchPopperContent}
                elevation={3}
              >
                <List>
                  {popularSearches.map((search) => (
                    <ListItem
                      button
                      key={search}
                      onClick={handleSearchPopverClose}
                    >
                      <ListItemIcon>
                        <SearchIcon />
                      </ListItemIcon>
                      <ListItemText primary={search} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </ClickAwayListener>
          </Popper> */}
        {/* <Button
            className={classes.trialButton}
            onClick={handlePricingModalOpen}
            variant="contained"
          >
            <LockIcon className={classes.trialIcon} />
            Upgrade
          </Button> */}
        {/* </Hidden> */}
        {/* <IconButton
          className={classes.chatButton}
          color="inherit"
          onClick={handleChatBarOpen}
        >
          <Badge
            badgeContent={6}
            color="secondary"
          >
            <PeopleIcon />
          </Badge>
        </IconButton> */}
        {/* <HelpOutlineOutlinedIcon
          onClick={() => setIsOpenModal(true)}
          className={classes.modalIcon}
        /> */}


        <IconButton
          className={classes.notificationsButton}
          style={{ color: tenantDetail.topbar_color ? tenantDetail.primary_color : '#fff' }}
          onClick={handleNotificationsOpen}
          ref={notificationsRef}
        >
          {unreadMsgsCount > 0 ? (
            <div className={classes.notification}>
              <NotificationsIcon className={classes.notificationIcon} />
              <div className={classes.notificationsBadge}
                style={{ border: '1px solid' + (tenantDetail.topbar_color ? tenantDetail.primary_color : '#fff') }}
              >
                <p>{`+ ${unreadMsgsCount}`}</p>
              </div>
              <span className={classes.notificationDot}></span>
            </div>

          ) : (
              <NotificationsIcon />
            )}
        </IconButton>
        <div className={classes.profile}>



          <Box
            display="flex"
            alignItems="center"
            component={ButtonBase}
            onClick={handleOpen}
            ref={ref}
          >
            <Avatar
              alt={session.user.first_name}
              className={classes.avatar}
              src={session.user.avatar === "null" || session.user.avatar === "" || session.user.avatar === null ? localStorage.getItem("userAvatar") :
                session.user.avatar }
            />
            {/* <Hidden lgUp> */}
            {/* </Hidden> */}
            <Hidden smDown>
              <Typography
                className={classes.names}
                style={{ color: tenantDetail.topbar_color ? tenantDetail.primary_color : '#fff' }}
                variant="h6"
                block
              >
                {`${session.user.first_name} ${session.user.last_name}`}
                {session.user.company &&
                  (
                    <>
                      <br />
                      <Tooltip title={<span style={{ fontSize: '14px' }}>{session.user.company}</span>}>
                        <Typography
                          className={classes.companyName}
                          style={{ color: tenantDetail.topbar_color ? tenantDetail.primary_color : '#fff' }}
                          variant="h6"
                          // color="inherit"
                          block
                        >
                          {`${session.user.company.length > 10 ? (session.user.company.substring(0, 10) + "..") : session.user.company}`}
                        </Typography>
                      </Tooltip>
                    </>
                  )}
              </Typography>
            </Hidden>
            <MenuIcon
              className={classes.companyName}
              style={{ color: tenantDetail.topbar_color ? tenantDetail.primary_color : '#fff' }}
            />
          </Box>
          <Menu
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            keepMounted
            PaperProps={{ className: classes.popover }}
            getContentAnchorEl={null}
            anchorEl={ref.current}
            open={isOpen}
          >
            <MenuItem
              component={RouterLink}
              to='/profile'
              onClick={handleClose}
            >
              My Profile
            </MenuItem>
            <MenuItem
              component={RouterLink}
              to="/my-projects"
              onClick={handleClose}

            >
              My Opportunities
            </MenuItem>
            <MenuItem
              component={RouterLink}
              to="/partners/outbound"
              onClick={handleClose}

            >
              My Network
            </MenuItem>
            <MenuItem
              component={RouterLink}
              to="/referrals/outbound"
              onClick={handleClose}

            >
              My Referrals
            </MenuItem>
            <MenuItem
              component={RouterLink}
              to="/notifications"
              onClick={handleClose}

            >
              Notification Settings
            </MenuItem>
            {session.user.ecosystemRole === 'Admin' &&
              <MenuItem
                component={RouterLink}
                to="/ecosystem-admin/dashboard"
                onClick={handleClose}

              >
                Ecosystem Admin
            </MenuItem>}
            {/* <MenuItem
          component={RouterLink}
          to='/profile'
        >
          Account
        </MenuItem> */}
            <MenuItem onClick={handleLogout}>
              Logout
        </MenuItem>


            {/* {ecosystems && ecosystems.length>1 && 

<>
<Button
  className={classes.ecosystemPickBlack}
  aria-controls="simple-menu" aria-haspopup="true"
  ref={anchorRef}
  onClick={handleMenuOpen}
>
  My Communities
<ArrowDropDownIcon />
</Button>
<Menu
  anchorEl={anchorRef.current}
  id="simple-menu"
  className={classes.menu}
  keepMounted
  onClose={handleMenuClose}
  open={openMenu}
  elevation={1}
  PaperProps={{ style: { width: 250 } }}
>
  {ecosystems.map((option) => (
    <MenuItem
      data-ecosystem={option.subdomain}
      className={classes.menuItem}
      onClick={handleEcosystemChange}
      key={option.subdomain}
      value={option.subdomain}
    >
      {option.name}
    </MenuItem>
  ))}
</Menu>
</> } */}



          </Menu>




        </div>
        {/* <Button
          className={classes.logoutButton}
          color="inherit"
          onClick={handleLogout}
        >
          <InputIcon className={classes.logoutIcon} />
          <Hidden mdDown>Sign out</Hidden>
        </Button> */}
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
        onRead={handleNotificationsMarkRead}
      />
      <PricingModal onClose={handlePricingModalClose} open={pricingModalOpen} />
      {/* <ChatBar onClose={handleChatBarClose} open={openChatBar} />
      <SwitchDirectionModal
        onClose={() => setOpenSwitchDirection(false)}
        onAccept={() => handleChangeDirectionModalAccept()}
        message={modalMessage}
        open={openSwitchDirection}
      /> */}
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  handleEcosystemChange: PropTypes.func,
  ecosystems: PropTypes.array
};

export default TopBar;
