import * as actionTypes from 'src/actions';

const initialState = {
  loggedIn: false,
  jwtToken: 'NOT LOADED',
  direction: 'outgoing',
  user: {
    first_name: 'Not',
    last_name: 'Logged In',
    email: '',
    avatar: '',
    bio: '',
    role: 'GUEST', // ['GUEST', 'USER', 'ADMIN']
    currency: 'AUD',
    testAvatar: ""
  },
  tenantDetail: 'NOT LOADED',
  primaryColor: '#000000',
};

const sessionReducer = (state = initialState, action) => {
// const sessionReducer = (state, action) => {
    switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      // console.log("LOGIN REDUCER2");
      // console.log(action);
      return {
        // ...initialState
          ...state,
          loggedIn: true,
          jwtToken: action.jwtToken,
          // direction: 'outgoing',
          // user: action.user
          user: {
            user_id: action.user.UserID,
            first_name:   action.user.FirstName,
            last_name: action.user.LastName,
            company: action.user.CompanyName,
            email: action.user.Email,
            avatar: action.user.Avatar,//'/images/avatars/avatar_11.png',
            bio: action.user.Bio,
            role: action.user.Role, // ['GUEST', 'USER', 'ADMIN']
            ecosystemRole: action.user.EcosystemRole, // ['GUEST', 'USER', 'ADMIN']
            currency: action.user.Currency && action.user.Currency !== '' ? action.user.Currency : 'AUD',
            location: action.user.Country,
            type: action.user.UserType,
            companyProfileUniqueIdentifier: action.user.CompanyProfileUniqueIdentifier,
            subscriptionStatus: action.user.SubscriptionStatus,
            subscriptionType: action.user.SubscriptionType,
            subscriptionExpiry: action.user.SubscriptionExpiry,
          }
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        jwtToken: null,
        // direction: 'outgoing',
        user: null
        // {
        //   role: 'GUEST'
        // }
      };
    }

    case actionTypes.CHANGE_DIRECTION: {
      return {
        ...state,
        direction: state.direction==='outgoing'?'incoming':'outgoing',
      };
    }

    case actionTypes.SET_DIRECTION: {
      return {
        ...state,
        direction: action.direction,
      };
    }

    case actionTypes.SET_TENANT_DETAILS: {
      return {
        ...state,
        tenantDetail: action.tenantDetail,
        primaryColor: action.primaryColor,
      };
    }
    default: {
      return state;
    }
  }
};

export default sessionReducer;
