import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  TextField,
  Divider,
  IconButton,
  Tooltip
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import VideoIcon from '@material-ui/icons/VideoCall';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import rtlCheck from 'src/utils/rtlCheck';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2)
  },
  paper: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5, 2),
    // border: '1px solid',
    // borderColor: theme.palette.primary.main
  },
  input: {
    width: '100%'
  },
  divider: {
    width: 1,
    height: 24
  },
  fileInput: {
    display: 'none'
  },
  helperText: {
    textAlign: 'right !important'
  }
}));

function ConversationForm({conversation, onChange, className, ...rest }) {
  const classes = useStyles();
  const fileInputRef = useRef(null);
  const [value, setValue] = useState('');

  const jwtToken = localStorage.getItem('jwtToken') || '';
  const history = useHistory();

  const handleChange = (event) => {
    event.persist();
    setValue(event.target.value);
  }


  const handleKeyDown = (event) =>{
    if (event.key === 'Enter' && event.shiftKey) {         
      setValue(event.target.value+'\n');
      return;
    }

    if (event.key === 'Enter' &&  event.target.value!=null && event.target.value!='' && event.target.value!='\n'){
      setValue(event.target.value);
      handleSend();
      event.preventDefault();
    }

    if (event.key === 'Enter'){
      event.preventDefault();
    }
  }

  const handleSend = ()=>{
    if(value!=null && value!='' && value!='\n'){
      if(conversation.conversation_id==-1){
        const params = new URLSearchParams();
        params.append('RecipientUserId', conversation.other_participant_id);
        params.append('Message', value);
        axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}api/user/MessengerSendMessageNewConversation`,
          headers: {
            'Authorization': 'Bearer ' + jwtToken,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: params
        })
        .then((response) => {
          if(response.data['ConversationId']){
            conversation.conversation_id = response.data['ConversationId'];
            console.log('conversation data',response.data)
            history.push("/chat/"+response.data['ConversationId']);
          }
           localStorage.removeItem("ConversationCompany");
        }, (error) => {
          console.log("send messages error");
          console.log(error);
          // localStorage.setItem("postLoginPath","/chat")
          // error.response.status===401 ? history.push('/auth/login') : window.alert("Oooops... An error has occured. Please contact support@bizforce.io. " + error);
        })
      }else{
        const params = new URLSearchParams();
        params.append('ConversationId', conversation.conversation_id);
        params.append('Message', value);
        axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}api/user/MessengerSendMessage`,
          headers: {
            'Authorization': 'Bearer ' + jwtToken,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: params
        })
        .then(() => {
         
        }, (error) => {
          console.log("send messages error");
          console.log(error);
          localStorage.setItem("postLoginPath","/chat")
          error.response.status===401 ? history.push('/auth/login') : window.alert("Oooops... An error has occured. Please contact support@bizforce.io. " + error);
        })
      }
      onChange(value);
    }
    setValue('');
  }

  const handleVideo = ()=>{
      if(conversation.conversation_id==-1){
        const params = new URLSearchParams();
        params.append('RecipientUserId', conversation.other_participant_id);
        params.append('Message', 'Started a video call:  https://meet.jit.si/bizforce_meeting_room_4kjl2' + conversation.conversation_id + '#config.prejoinPageEnabled=true&config.remoteVideoMenu.disableKick=true' );
        axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}api/user/MessengerSendMessageNewConversation`,
          headers: {
            'Authorization': 'Bearer ' + jwtToken,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: params
        })
        .then((response) => {
          if(response.data['ConversationId']){
            conversation.conversation_id = response.data['ConversationId'];
            history.push("/chat/"+response.data['ConversationId']);
          }
           localStorage.removeItem("ConversationCompany");
        }, (error) => {
          console.log("send messages error");
          console.log(error);
          // localStorage.setItem("postLoginPath","/chat")
          // error.response.status===401 ? history.push('/auth/login') : window.alert("Oooops... An error has occured. Please contact support@bizforce.io. " + error);
        })
      }else{
        const params = new URLSearchParams();
        params.append('ConversationId', conversation.conversation_id);
        params.append('Message', 'Started a video call:  https://meet.jit.si/bizforce_meeting_room_4kjl2' + conversation.conversation_id + '#config.prejoinPageEnabled=true&config.remoteVideoMenu.disableKick=true' );
        axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}api/user/MessengerSendMessage`,
          headers: {
            'Authorization': 'Bearer ' + jwtToken,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: params
        })
        .then(() => {
         
        }, (error) => {
          console.log("send messages error");
          console.log(error);
          localStorage.setItem("postLoginPath","/chat")
          error.response.status===401 ? history.push('/auth/login') : window.alert("Oooops... An error has occured. Please contact support@bizforce.io. " + error);
        })
      }
      onChange(value);

      window.open('https://meet.jit.si/bizforce_meeting_room_4kjl2' + conversation.conversation_id + '#config.prejoinPageEnabled=true&config.remoteVideoMenu.disableKick=true', "_blank")

  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {/* <Avatar
        alt="Person"
        src={sender.avatar}
      /> */}
        <TextField
          style={{ direction: rtlCheck(value)?'rtl':'ltr' }}
          multiline
          autoFocus
          className={classes.input}
          onChange={handleChange}
          value={value}
          onKeyDown={(event) => handleKeyDown(event)}
          inputProps={{ maxLength: 5000 }}
          helperText={`${value ? value.length : 0}/5000`}
          FormHelperTextProps={{
            className: classes.helperText
          }}
          placeholder="Write a message..."
        />
      <Tooltip title={<span style={{fontSize: '14px'}}>Send</span>}>
        <IconButton color={value.length > 0 ? 'primary' : 'default'}  onClick={handleSend}>
          <SendIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={<span style={{fontSize: '14px'}}>Start a Video</span>}>
        <IconButton color={value.length > 0 ? 'primary' : 'default'}  onClick={handleVideo}>
          <VideoIcon />
        </IconButton>
      </Tooltip>
      <Divider className={classes.divider} />
      {/* <Tooltip title="Attach photo">
        <IconButton
          edge="end"
          onClick={handleAttach}
        >
          <AddPhotoIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Attach file">
        <IconButton
          edge="end"
          onClick={handleAttach}
        >
          <AttachFileIcon />
        </IconButton>
      </Tooltip>
      <input
        className={classes.fileInput}
        ref={fileInputRef}
        type="file"
      /> */}
    </div>
  );
}

ConversationForm.propTypes = {
  conversation: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string
};

export default ConversationForm;
