import React, {useEffect, useState} from 'react'
import ConversationList from '../ConversationList/index'
import {useHistory} from 'react-router-dom'
import {makeStyles} from '@material-ui/core'
import Members from './Member/Index'
import Rooms from './Rooms'
import UpcomingEvents from './UpcomingEvents/Index'
const useStyles = makeStyles((theme) => ({
    sidebarLeft: {
        position: 'sticky',
        top: 40,
        // left: '1%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
//         alignItems: 'center',
        width: '100%',
        minWidth: '15rem',
        maxWidth: '20rem',
        // marginRight: 10,
        padding: 10,
        overflowY: 'auto',
        overflow: "hidden",
        zIndex: 1,
        fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
        ['@media (max-width:900px)']: { 
          display: 'none'
          },
          [`@media only screen 
          and (min-device-width: 768px) 
          and (max-device-width: 1024px) 
          and (-webkit-min-device-pixel-ratio: 1)`] : {
          top: '5%'
          }
    },

}))

function SidebarLeft() {
    const classes = useStyles()
  
    return (
        <div className={classes.sidebarLeft}>
          {/* <Rooms /> */}
          <Members />
          <UpcomingEvents />

        </div>
    )
}

export default SidebarLeft
