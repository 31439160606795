import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import Page from 'src/components/Page';
import ConversationList from './ConversationList';
import ConversationDetails from './ConversationDetails';
import ConversationPlaceholder from './ConversationPlaceholder';
import { useHistory } from 'react-router-dom';
import jwt from 'jwt-decode';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    overflow: 'hidden',
    '@media (max-width: 863px)': {
      '& $conversationList, & $conversationDetails': {
        flexBasis: '100%',
        width: '100%',
        // maxWidth: 'none',
        // flexShrink: '0',
      }
    }
  },
  openConversion: {
    '@media (max-width: 863px)': {
      '& $conversationList, & $conversationDetails': {
        // transform: 'translateX(-100%)'
      }
    }
  },
  conversationList: {
    width: 300,
    flexBasis: 300,
    flexShrink: 0,
    '@media (max-width: 864px)': {
      borderRight: `1px solid ${theme.palette.divider}`,
      display: 'none'

    }
  },
  conversationDetails: {
    flexGrow: 1
  },
  conversationPlaceholder: {
    flexGrow: 1
  }
}));

function Chat() {
  const classes = useStyles();
  const params = useParams();
  const [conversations, setConversations] = useState([]);
  const jwtToken = localStorage.getItem('jwtToken') || '';
  const [selectedConversation, setSelectedConversation] = useState(null);
  const history = useHistory();

  const fetchConversations = () => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}api/user/MessengerAllConversations`,
      headers: {
        Authorization: 'Bearer ' + jwtToken
      }
    }).then(
      response => {
        setConversations(response.data);
      },
      error => {
        console.log('fetch conversations error');
        console.log(error);
        localStorage.setItem('postLoginPath', "/chat/" + (params.id ? params.id : "-1"));
        error.response.status === 401
          ? history.push('/auth/login')
          : window.alert(
              'Oooops... An error has occured. Please contact support@bizforce.io. ' +
                error
            );
      }
    );
  };

  useEffect(() => {
    fetchConversations();
    const interval = setInterval(() => {
      fetchConversations();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (params.id) {
      //new conversation
      if (
        params.id === '-1' &&
        conversations.findIndex(c => c.conversation_id === '-1') === -1
      ) {
        const jwtToken = localStorage.getItem('jwtToken') || '';
        const user = jwt(jwtToken); // decode your token here

        var stringCompany = localStorage.getItem(
          'ConversationCompany',
          companyProfile
        );
        if (stringCompany !== null && stringCompany !== '') {
          var companyProfile = JSON.parse(stringCompany);
          var newConversation = {
            conversation_id: params.id,
            other_participant_first_name:
              companyProfile.company_contact_first_name,
            other_participant_last_name:
              companyProfile.company_contact_last_name,
            other_participant_id: companyProfile.user_id,
            other_participant_avatar: companyProfile.avatar,
            last_message_content: '',
            last_message_user_id: user.UserID,
            last_message_time: new Date(),
            last_message_first_name: user.FirstName,
            last_message_last_name: user.LastName,
            company_name: companyProfile.company_name,
            company_avatar: companyProfile.company_avatar,
            unread_messages: 0
          };

          var existingConversation = false;
          for (let conversation of conversations) {
            if (
              conversation.conversation_id !== -1 &&
              conversation.other_participant_id ===
                newConversation.other_participant_id
            ) {
              history.replace('/chat/' + conversation.conversation_id);
              existingConversation = true;
              setSelectedConversation(conversation);
              break;
            }
          }

          if (!existingConversation) {
            conversations.splice(0, 0, newConversation);
            setSelectedConversation(newConversation);
          }
        }
      } else {
        setSelectedConversation(
          conversations.find(c => c.conversation_id == params.id)
        );
      }
    } else {
      setSelectedConversation(null);
    }
    // else if (conversations && conversations.length>0){
    //   setSelectedConversation(conversations[0]);
    // }
  }, [conversations, params]);

  const handleSelectConversation = conversation => {
    setSelectedConversation(conversation);
  };

  return (
    <Page
      className={clsx({
        [classes.root]: true,
        [classes.openConversion]: selectedConversation
      })}
      title="BizForce Chat"
    >
      <ConversationList
        onSelectConversation={handleSelectConversation}
        className={classes.conversationList}
        conversations={conversations}
      />
      {selectedConversation ? (
        <ConversationDetails
          className={classes.conversationDetails}
          conversation={selectedConversation}
        />
      ) : (
        <ConversationPlaceholder className={classes.conversationPlaceholder} />
      )}
    </Page>
  );
}

export default Chat;
