import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Dialog,
  Grid,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  CardMedia,
} from '@material-ui/core';
import PostCard from './PromotedCard';
// import AddPost from 'src/componentsnew/AddPostLibrary';
import jwt from 'jwt-decode';
import axios from 'axios';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  resourcesStyle: {
    // width: '100vw !important',

  },
  title: {
    position: 'relative',
    width: '100%',
    height: '50px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 10px rgba(34, 36, 36, 0.03)',
    borderRadius: 10,
    lineHeight: '50px',
    paddingLeft: '15px',
    fontFamily: 'Gilroy',
    fontWeight: 600,
    fontSize: '14px',
    color: ' #808091',
  },
  media: {
    height: '100%',
    width: '100%',
    backgroundSize: 'contain !important'
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ResourcesCategory() {
  const classes = useStyles()
  const jwtToken = localStorage.getItem('jwtToken') || '';
  const [imageUrl, setImageUrl] = useState(null);
  const [open, setOpen] = useState(false);

  const [posts, setPosts] = useState([]);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = () => {

    const params = new URLSearchParams();
    
    params.append("PostCategory", 'Promoted content');
    params.append("PageSize", '1');
    params.append("PageIndex", '1');

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}api/user/GetLibraryPostsFilters`,
      headers: {
        'Authorization': 'Bearer ' + jwtToken
      },
      data: params
    }).then((response) => {
      setPosts(response.data.Posts);
      // setComments(response.data.Comments);
    }, (error) => {
      console.log("GetLibraryPosts error");
      console.log(error);    
    })
  };

  const handleOpenImage = (url) => {
    if (url != null && url != '') {
      setImageUrl(url);
      setOpen(true);
    }
  }
  const handleClose = () => {
    setOpen(false);
  };


  if (!posts) {
    return null;
  }
  return (
    <div style={{ marginTop: '12px', position: 'relative' }}>
      {/* <h1 
        id="back-to-top-anchor"
        className={classes.categoryType}
      >
        {categoryTitle}
      </h1> */}
      <Grid
        className={classes.resourcesStyle}
        container
        spacing={2}
      >
        {posts && posts.map((post) => (
          <Grid item md={12} xs={12} style={{ marginBottom: 15, }}>
            <PostCard
              className={classes.post}
              key={post.id}
              onOpenImage={handleOpenImage}
              post={post}
              comments={comments ? comments.filter(item => item.parent_id == post.id) : []}
              onCommentCompleted={fetchPosts}
              onDeleteMessageCompleted={fetchPosts}
            />
          </Grid>

        ))}
      </Grid>
      {/* {posts && posts.length > 2 && (!lessButton ? <div onClick={showMore} className={classes.button}>
        <ArrowDropDown />
          show {posts.length - 2} more
        </div> :
        <div onClick={showLess} className={classes.button}>
          <ArrowBack />
          show less
        </div>
      )} */}
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Image Preview
                </Typography>
          </Toolbar>
        </AppBar>
        <CardMedia
          className={classes.media}
          image={imageUrl}
        />
      </Dialog>
    </div>

  )
}

export default ResourcesCategory