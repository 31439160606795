import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  IconButton,
  Link,
  Toolbar,
  Tooltip,
  Typography,
  Hidden
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import StatusBullet from 'src/components/StatusBullet';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white
  },
  backButton: {
    marginRight: theme.spacing(2),
    '@media (min-width: 864px)': {
      display: 'none'
    }
  },
  user: {
    flexShrink: 0,
    flexGrow: 1
  },
  activity: {
    display: 'flex',
    alignItems: 'center'
  },
  statusBullet: {
    marginRight: theme.spacing(1)
  },
  search: {
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    flexBasis: 300,
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 auto'
    }
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon
  },
  searchInput: {
    flexGrow: 1
  }
}));

function ConversationToolbar({ conversation, className, ...rest }) {
  const classes = useStyles();

  return (
    <Toolbar {...rest} className={clsx(classes.root, className)}>
      <Hidden smDown>
      <Tooltip title={<span style={{ fontSize: '14px' }}>Back</span>}>
        <IconButton
          className={classes.backButton}
          component={RouterLink}
          edge="start"
          to="/chat"
        >
          <KeyboardBackspaceIcon />
        </IconButton>
      </Tooltip>
      </Hidden>
      <div className={classes.user}>
        {conversation.company_id ? (
          <Link
            className={classes.companyName}
            color="textPrimary"
            component={RouterLink}
            to={'/partner/' + conversation.company_id}
            variant="h5"
          >
            {conversation.company_name}
          </Link>
        ) : (
          <Typography variant="h6">{conversation.company_name}</Typography>
        )}
        <Typography variant="h6">
          {conversation.other_participant_first_name +
            ' ' +
            conversation.other_participant_last_name}
        </Typography>
        {conversation.last_active && (
          <div className={classes.activity}>
            {Date.parse(conversation.last_active) + 1000 * 60 * 2 >
            Date.parse(Date()) ? (
              <>
                <StatusBullet
                  className={classes.statusBullet}
                  color="success"
                  size="small"
                />
                <Typography variant="body2">Active now</Typography>
              </>
            ) : (
              <Typography variant="body2">
                Active {moment(conversation.last_active).fromNow()}
              </Typography>
            )}
          </div>
        )}
      </div>
      {/* <Hidden smDown>
        <Paper
          className={classes.search}
          elevation={1}
        >
          <SearchIcon className={classes.searchIcon} />
          <Input
            className={classes.searchInput}
            disableUnderline
            placeholder="Search email"
          />
        </Paper>
      </Hidden> */}
      {/* <Tooltip title="More options">
        <IconButton
          onClick={handleMenuOpen}
          ref={moreRef}
        >
          <MoreIcon />
        </IconButton>
      </Tooltip> */}
      {/* <Menu
        anchorEl={moreRef.current}
        keepMounted
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
      >
        <MenuItem>
          <ListItemIcon>
            <BlockIcon />
          </ListItemIcon>
          <ListItemText primary="Block user" />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Delete conversation" />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <ArchiveIcon />
          </ListItemIcon>
          <ListItemText primary="Archive conversation" />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <NotificationsOffIcon />
          </ListItemIcon>
          <ListItemText primary="Mute notifications" />
        </MenuItem>
      </Menu> */}
    </Toolbar>
  );
}

ConversationToolbar.propTypes = {
  className: PropTypes.string,
  conversation: PropTypes.object.isRequired
};

export default ConversationToolbar;
