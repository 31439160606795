import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Dialog,
  Divider,
  Grid,
  Paper,
  Typography,
  colors
} from '@material-ui/core';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router';


const useStyles = makeStyles((theme) => ({
  root: {
    width: 960,
    width: '100%'
  },
  header: {
    maxWidth: 800,
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(2),
    maxWidth: 950,
    margin: '0 auto'
  },
  product: {
    overflow: 'visible',
    position: 'relative',
    padding: theme.spacing(5, 1),
    cursor: 'pointer',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '&:hover': {
      transform: 'scale(1.05)'
    }
  },
  image: {
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    top: -24,
    left: theme.spacing(3),
    height: 48,
    width: 48,
    fontSize: 24
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  options: {
    lineHeight: '26px'
  },
  recommended: {
    backgroundColor: theme.palette.primary.main,
    '& *': {
      color: `${theme.palette.common.white} !important`
    }
  },
  contact: {
    margin: theme.spacing(3, 0)
  },
  actions: {
    backgroundColor: colors.grey[100],
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  startButton: {
    color: theme.palette.common.white,
    backgroundColor: colors.green[800],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));


function PricingModal({
  open, onClose, className, ...rest
}) {
  const classes = useStyles();
  const history = useHistory();

  const onSelectSubscriprion = selection => {

    // TODO - change this with a nice looking modal.
    const message =
      // selection==="Free"?"Who said that good things aren't free? You can use our platform completely free of charge to start growing your business. Do you wish to continue?":
      "Good choice! Your first 30 days on this plan are free. No credit card required. Do you wish to continue?"

    if (window.confirm(message)) {
      let mounted = true;
      const jwtToken = localStorage.getItem('jwtToken') || '';
      const axios = require('axios');
      let filter =
        'Name=' + selection

      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}api/user/SetUserSubscription`,
        headers: {
          'Authorization': 'Bearer ' + jwtToken
        },
        data: filter
      }).then(() => {
        if (mounted) {
          Cookies.set('userSubscription', selection, { expires: 365 });
          onClose(selection);
          // history.push('/profile/subscription');
        }
      }, (error) => {
        console.log("SetUserSubscription error");
        console.log(error);
        localStorage.setItem("postLoginPath", '/profile/subscription')
        error.response.status === 401 ? history.push('/auth/login') : window.alert("Oooops... An error has occured. Please contact support@bizforce.io. SendPartnershipInvite" + error);

      })
    }
  };

  return (
    <Dialog
      maxWidth="lg"
      onClose={onClose}
      open={open}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <div className={classes.header}>
          <Typography
            align="center"
            gutterBottom
            variant="h3"
          >
            Grow your business with more opportunities and leads!
          </Typography>
          <Typography
            align="center"
            className={classes.subtitle}
            variant="subtitle2"
          >
            BizForce allows you to manage your referral program and grow your business quickly and easily through new partner referrals or maximising existing connections.
          </Typography>
        </div>
        <div className={classes.content}>
          <Grid
            container
            spacing={2}
            align="center"
            alignItems="center"
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <Paper
                // className={classes.product}
                className={clsx(classes.product, classes.recommended)}
                elevation={1}
                onClick={() => onSelectSubscriprion("Free")}
              >
                <img
                  alt="Product"
                  className={classes.image}
                  src="/images/products/product_freelancer.svg"
                />
                <Typography
                  component="h3"
                  gutterBottom
                  variant="overline"
                >
                  Collaboration Hub
                </Typography>
                <div>
                  <Typography
                    component="span"
                    display="inline"
                    variant="h3"
                  >
                    $9.95
                  </Typography>
                  <Typography
                    component="span"
                    display="inline"
                    variant="subtitle2"
                  >
                    /month
                  </Typography>
                </div>
                <Typography>Practical networking for SMEs</Typography>
                <Divider className={classes.divider} />
                <Typography
                  className={classes.options}
                  variant="subtitle2"
                >
                  <b>List</b>
                  {' '}
                  your company profile
                  <br />
                  <b>Access</b>
                  {' '}
                  collaboration opportunities
                  <br />
                  50
                  {' '}
                  <b>referral partners</b>
                  <br />
                  <b>Receive</b>
                  {' '}
                  5 referrals/month
                  {' '}
                  <br />
                  <b>Send</b>
                  {' '}
                  20 referrals/month
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Paper
                className={clsx(classes.product, classes.recommended)}
                elevation={1}
                onClick={() => onSelectSubscriprion("Basic")}
              >
                <img
                  alt="Product"
                  className={classes.image}
                  src="/images/products/product_agency--outlined.svg"
                />
                <Typography
                  component="h3"
                  gutterBottom
                  variant="overline"
                >
                  Partnership management
                </Typography>
                <div>
                  <Typography
                    component="span"
                    display="inline"
                    variant="h3"
                  >
                    59.95
                  </Typography>
                  <Typography
                    component="span"
                    display="inline"
                    variant="subtitle2"
                  >
                    /month
                  </Typography>
                </div>
                <Typography variant="overline">Scale your partner program</Typography>
                <Divider className={classes.divider} />
                <Typography
                  className={classes.options}
                  variant="subtitle2"
                >
                  Referrals
                  {' '}
                  <b>management</b>
                  <br />
                  Analytics
                  {' '}
                  <b>dashboard</b>
                  <br />
                  50
                  {' '}
                  <b>referral partners</b>
                  <br />
                  <b>Receive</b>
                  {' '}
                  10 referrals/month
                  {' '}
                  <br />
                  <b>Send</b>
                  {' '}
                  100 referrals/month
                </Typography>
              </Paper>
            </Grid>
            {/* <Grid
              item
              md={4}
              xs={12}
            >
              <Paper
                className={classes.product}
                elevation={1}
                onClick={event => onSelectSubscriprion("Premium")}
              >
                <img
                  alt="Product"
                  className={classes.image}
                  src="/images/products/product_enterprise.svg"
                />
                <Typography
                  component="h3"
                  gutterBottom
                  variant="overline"
                >
                  Premium
                </Typography>
                <div>
                  <Typography
                    component="span"
                    display="inline"
                    variant="h3"
                  >
                    $499
                  </Typography>
                  <Typography
                    component="span"
                    display="inline"
                    variant="subtitle2"
                  >
                    /month
                  </Typography>
                </div>
                <Typography variant="overline">Max 10 users</Typography>
                <Divider className={classes.divider} />
                <Typography
                  className={classes.options}
                  variant="subtitle2"
                >
                  <b>Unlimited/mth</b>
                  {' '}
                  referrals (in)
                  {' '}
                  <br />
                  <b>Unlimited/mth</b>
                  {' '}
                  referrals (out)
                  {' '}
                  <br />
                  <b>Unlimited</b>
                  {' '}
                  referral partners
                  {' '}
                  <br />
                  Analytics
                  {' '}
                  <b>dashboard</b>
                  {' '}
                  <br />
                  <b>Advertise</b>
                  {' '}
                  your profile
                  {' '}
                  <br />
                  24/7 support
                  {' '}
                  <br />
                  <b>CRM</b>
                  {' '}
                  integration
                </Typography>
              </Paper>
            </Grid> */}
          </Grid>
          <Typography
            align="center"
            className={classes.contact}
            variant="subtitle2"
          >
            Membership groups, ecosystems or have a larger team? Contact us on
            {' '}
            <a href="mailto:contact@bizforce.io">contact@bizforce.io</a>
            {/* <Link
            color="secondary"
            component={RouterLink}
            to="/contactus"
          >
            Contact Us
          </Link> */}
            {' '}
            for information about enterprise options.
          </Typography>
        </div>
        <div className={classes.actions}>
          <Button
            className={classes.startButton}
            onClick={() => onClose("Close")}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

PricingModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default PricingModal;
