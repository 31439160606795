import React, { useEffect, useState  } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import axios from 'axios';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  logoText: {
    color: '#FFFFFF'
  }
}));

function Topbar({ className, ...rest }) {
  const classes = useStyles();
  const [tenantDetail, setTenantDetail] = useState(
    localStorage.getItem("TenantDetail")?JSON.parse(localStorage.getItem("TenantDetail")):{}  );

  useEffect(() => {
    let mounted = false;
    if(!mounted){
      let subDomain =window.location.hostname.replace('.bizforce.io','');
      axios.get(`${process.env.REACT_APP_API_URL}api/ecosystem/GetEcosystemDetails/`+subDomain)
      .then((res)=>{
        if(res!=null && res.data!==null && res.data.length>0){
          var tenantInfo = res.data[0];
          tenantInfo.subdomain = subDomain;
          localStorage.setItem("TenantDetail", JSON.stringify( res.data[0]));
          setTenantDetail(res.data[0]);
        }
      });
      mounted = true;
    }
  }, []);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
    >
      <Toolbar>
        {/* <Typography className={classes.logoText}
          variant="h2"
        >
            BizForce
          </Typography> */}
          <img
            alt="Logo"
            // width={175}
            height={50}
            src={tenantDetail.logo}
            // src="/images/logos/bizforce-logo-white.svg"
          />
        {/* </RouterLink> */}
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
