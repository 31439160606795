import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import ConversationList from './ConversationList/index'
import {makeStyles} from '@material-ui/core'
import Chat from '../../viewsnew/Chat/index'
const useStyles = makeStyles((theme) => ({
    conversationList: {
        position: 'relative',
        top: 27,
        width: '100%',
        flexBasis: 250,
        flexShrink: 0,
        '@media (min-width: 864px)': {
          borderRight: `1px solid ${theme.palette.divider}`
        }
      },
}))


function ConversationBox({CloseConversationList}) {
    const classes = useStyles()
    const [conversations, setConversations] = useState([]);
    let jwtToken = localStorage.getItem('jwtToken') || '';
    const axios = require('axios');
  const [selectedConversation, setSelectedConversation] = useState(null);
  const history = useHistory();

    const fetchConversations = () => {
        axios({
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}api/user/MessengerAllConversations`,
          headers: {
            Authorization: 'Bearer ' + jwtToken
          }
        }).then(
          response => {
            setConversations(response.data);
          },
          error => {
            console.log('fetch conversations error');
            console.log(error);
            // localStorage.setItem('postLoginPath', '/chat');
            error.response.status === 401
              ? history.push('/auth/login')
              : window.alert(
                'Oooops... An error has occured. Please contact support@bizforce.io. ' +
                error
              );
          }
        );
      };
    
      useEffect(() => {
        fetchConversations();
        const interval = setInterval(() => {
          fetchConversations();
        }, 30000);
        return () => clearInterval(interval);
      }, []);

    const handleSelectConversation = conversation => {
        setSelectedConversation(conversation);
      };
    return (
        <div>
               <ConversationList
            onSelectConversation={handleSelectConversation}
            className={classes.conversationList}
            conversations={conversations}
            CloseConversationList={CloseConversationList}
            /> 
        </div>
    )
}

export default ConversationBox
