import React, { createContext, Suspense, useEffect, useState, useRef } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress, Hidden, Typography, Grid } from '@material-ui/core';
// import NavBar from './NavBar';
import TopBar from './TopBar';
import isEqual from '../../utils/isEqual';
import { setTimeout } from 'timers';
import SidebarLeft from './sidebarLeft/Index'
import SidebarRight from './sidebarRight'
import MessageIcon from '@material-ui/icons/Message';
import ConversationBox from './ConversationBox'
import { useSelector } from 'react-redux';

// import HomeIcon from '@material-ui/icons/Home';
// import NavigationMobile from 'src/componentsnew2/NavigationMobile';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: window.innerHeight,
    width: '100%',
    display: 'flex',
    height: '100%',
    paddingRight: 0,
    paddingLeft: 40,
  '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 0,
    }
  },
  content: {
    paddingTop: 40,
    flexGrow: 1,
    flex: '0.4',
    maxWidth: '100%',
    overflowX: 'hidden',
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: 100
    // },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 40
    }
  },

  containerWrapper: {
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: -4,
    width: '100%'
    // paddingLeft: 10,
    // paddingRight: 10,

  },
  SidebarLeft: {
    top: 40,
    flex: '0.3',
    height: '100vh',
    // backgroundColor: '#FBFBFB',
    color: 'black',

  },
  SidebarRight: {
    // paddingLeft: 20,
    // backgroundColor: '#FBFBFB',
    height: '100vh',
    top: 40,
    flex: '0.3',
    // overflowY: 'scroll',


  },
  float: {
    position: 'fixed',
    bottom: 37,
    right: '5%',
    width: '20rem',
    boxShadow: `0px 30px 5px gray`,
    zIndex: 1000,
    ['@media (max-width:700px)']: {
    bottom: 125,
    }
  },
  floatButton: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    width: '3rem',
    height: '3rem',
    borderRadius: '50%',
    backgroundColor: '#fff',
    display: 'grid',
    placeItems: 'center',
    boxShadow: `0px 10px 5px gray`,
    border: `1px solid gray`,
    zIndex: 1000,
    cursor: 'pointer',
    ['@media (max-width:700px)']: {
      bottom: 90
    }
  },
  messageIcon: {
    color:  '#232c66d7',
    '&:hover': {
      color: '#232c66',
    }
  },
  unreadCoount: {
    position: 'absolute',
    top: -10,
    right: -13,
    width: '1.8rem',
    height: '1.8rem',
    // borderRadius: '50%',
    // backgroundColor: '#fb8c00',
    color: '#fff',
    display: 'grid',
    placeItems: 'center',
    borderRadius: '50%',
    backgroundColor: '#ec640b',
  }
}));

export const UserNotificationContext = createContext();

function Dashboard({ route }) {
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [unreadCount, setUnreadCount] = useState({
    incoming_referral_count: 0,
    incoming_partnership_count: 0,
    outgoing_partnership_count: 0
  });

  const session = useSelector(state => state.session);

  const ref = useRef()
  let jwtToken = localStorage.getItem('jwtToken') || '';
  const axios = require('axios');
  const [ecosystems, setEcosystems] = useState([]);
  const [showConversation, setShowConversation] = useState(false)
  const [floatButtonClicked,setFloatButtonClicked ] = useState(false)

  const getData = async () => {
    const res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}api/User/GetUserNotificationsCount`,
      headers: {
        Authorization: 'Bearer ' + jwtToken
      }
    });
    if (!isEqual(res.data[0], unreadCount)) {
      localStorage.setItem('userNotifications',JSON.stringify(res.data[0]))
      setUnreadCount(res.data[0]);
    }

    setTimeout(() => {
      jwtToken = null;
      jwtToken = localStorage.getItem('jwtToken') || '';
      if (jwtToken !== '') {
        getData()
      }
    }, 20000);
  };

  const handleEcosystemChange = (event) => {
    const { ecosystem } = event.currentTarget.dataset;
    window.location.href = "https://" + ecosystem + ".bizforce.io";
  }

  const getUserEcosystems = () => {
    const jwtToken = localStorage.getItem('jwtToken') || '';
    // Check the direction from URL and set the session value accordingly

    const axios = require('axios');

    let mounted = true;
    const fetchUserEcosystems = () => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}api/ecosystem/GetUserEcosystems`,
        headers: {
          'Authorization': 'Bearer ' + jwtToken
        }
      }).then(
        response => {
          if (mounted) {
            if (response != null && response.data !== null) {
              setEcosystems(response.data);
            }
          }
        },
        error => {
          console.log('GetUserEcosystems error');
          console.log(error);
        }
      );
    };

    fetchUserEcosystems();

    return () => {
      mounted = false;
    };
  };

  const handleConversation = () => {
    setShowConversation((prevState) => !prevState)

  }
  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setShowConversation(false)
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })



  // useEffect(() => {
  //   getUserEcosystems();
  // }, []);

  useEffect(() => {
    if (jwtToken !== '') {
      getUserEcosystems();
      getData();
    }
  }, []);

 

  return (
    <div className={classes.containerWrapper}>
      <UserNotificationContext.Provider value={[unreadCount, setUnreadCount]}>
    
        <TopBar
          ecosystems={ecosystems}
          onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
          handleEcosystemChange={handleEcosystemChange}
        />
          
        {/* <NavBar
          ecosystems={ecosystems}
          onMobileClose={() => setOpenNavBarMobile(false)}
          openMobile={openNavBarMobile}
          handleEcosystemChange={handleEcosystemChange}
        /> */}
       <Grid xs={12} style={{display: 'flex',position: 'relative', justifyContent: 'space-between'}}>
        <Grid md={4} lg={3}>
        <SidebarLeft className={classes.SidebarLeft}/>
          </Grid>
        <Grid xs={12} md={8} lg={6}> 
        <div className={classes.container}>
          <div className={classes.content}>
            <Suspense fallback={<LinearProgress />}>
              {renderRoutes(route.routes)}
            </Suspense>
          </div>
        </div>
          </Grid>
        <Grid lg={3}>      
        <SidebarRight className={classes.SidebarRight} unreadCount={unreadCount}/>
           </Grid>
        </Grid>
        {showConversation && <div className={classes.float} ref={ref}>
          <ConversationBox CloseConversationList={() => setShowConversation(false)}/>
        </div>}
        <div className={classes.floatButton} onClick={handleConversation}>
          <MessageIcon className={classes.messageIcon}/>
         {unreadCount.messenger_messages_count > 0 && (<Typography className={classes.unreadCoount}>{unreadCount.messenger_messages_count}</Typography>)}

        </div>
      </UserNotificationContext.Provider>
    </div>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
