export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const CHANGE_DIRECTION = 'CHANGE_DIRECTION';
export const SET_DIRECTION = 'SET_DIRECTION';
export const SET_TENANT_DETAILS = 'SET_TENANT_DETAILS';

// export const login = () => (dispatch) => dispatch({
export const login = (jwtToken, user) => (dispatch) => dispatch({
    type: SESSION_LOGIN,
    jwtToken: jwtToken,
    user: user
});

export const logout = () => (dispatch) => dispatch({
  type: SESSION_LOGOUT
});
 
export const changeDirection = () => (dispatch) => dispatch({
  type: CHANGE_DIRECTION,
  // direction: !direction,
});

export const setDirection = (direction) => (dispatch) => dispatch({
  type: SET_DIRECTION,
  direction: direction,
});

export const setTenantDetail = (tenantDetail) => (dispatch) => dispatch({
  type: SET_TENANT_DETAILS,
  tenantDetail: tenantDetail,
  primaryColor: tenantDetail.primary_color
});

