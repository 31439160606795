import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider } from '@material-ui/core';
import ConversationToolbar from './ConversationToolbar';
import ConversationMessages from './ConversationMessages';
import ConversationForm from './ConversationForm';
import axios from 'axios';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white
  }
}));

function ConversationDetails({ conversation, className, ...rest }) {
  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  const urlParams = useParams();
  const jwtToken = localStorage.getItem('jwtToken') || '';
  const history = useHistory();

  const fetchConversationDetails = () => {
    const params = new URLSearchParams();
    params.append(
      'ConversationId',
      conversation.conversation_id ? conversation.conversation_id : urlParams.id
    );

    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}api/user/MessengerGetMessagesForConversation`,
      headers: {
        Authorization: 'Bearer ' + jwtToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: params
    }).then(
      response => {
        setMessages(response.data);
      },
      error => {
        console.log('fetch conversation messages error');
        console.log(error);
        localStorage.setItem(
          'postLoginPath',
          '/chat/' + urlParams.id && urlParams.id != '-1'
            ? '/' + urlParams.id
            : ''
        );
        error.response.status === 401
          ? history.push('/auth/login')
          : window.alert(
              'Oooops... An error has occured. Please contact support@bizforce.io. ' +
                error
            );
      }
    );
  };

  useEffect(() => {
    if (conversation) {
      fetchConversationDetails();
      const interval = setInterval(() => {
        fetchConversationDetails();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [conversation]);

  const handleNewMessage = message => {
    const base64Url = jwtToken.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    var authUser = JSON.parse(window.atob(base64));

    setMessages(prevValues => {
      var newValues = [...prevValues];
      newValues.push({
        id: '-1',
        message_content: message,
        message_user_id: authUser.UserID,
        message_time: new Date(),
        message_last_name: authUser.FirstName,
        message_last_name1: authUser.LastName,
        message_avatar: null
      });
      return newValues;
    });
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <ConversationToolbar conversation={conversation} />
      <Divider />
      <ConversationMessages messages={messages} />
      <Divider />
      <ConversationForm
        conversation={conversation}
        onChange={handleNewMessage}
      />
    </div>
  );
}

ConversationDetails.propTypes = {
  className: PropTypes.string,
  conversation: PropTypes.object.isRequired
};

export default ConversationDetails;
