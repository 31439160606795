import uuid from 'uuid/v1';
import { colors } from '@material-ui/core';
import mock from 'src/utils/mock';

mock.onGet('/api/dashboard/top-partners').reply(200, {
  partners: [
    {
      id: uuid(),
      color: colors.blueGrey['700'],
      name: 'Palse',
      avatar: '/images/avatars/avatar_1.png',
      initials: 'GT',
      intercations: '153',
      referrals_sent: '53',
      referrals_converted: '77'
    },
    {
      id: uuid(),
      color: colors.cyan['500'],
      name: 'BCA Group',
      avatar: '/images/avatars/avatar_2.png',
      initials: 'TW',
      intercations: '43',
      referrals_sent: '39',
      referrals_converted: '53'
    },
    {
      id: uuid(),
      color: colors.indigo[600],
      name: 'Hacker News',
      avatar: '/images/avatars/avatar_3.png',
      initials: 'HN',
      intercations: '57',
      referrals_sent: '23',
      referrals_converted: '85'
    },
    {
      id: uuid(),
      color: colors.red['500'],
      name: 'Stack Overflow',
      avatar: '/images/avatars/avatar_4.png',
      initials: 'SO',
      intercations: '142',
      referrals_sent: '14',
      referrals_converted: '76'
    },
    {
      id: uuid(),
      color: colors.orange['900'],
      name: 'Reddit',
      avatar: '/images/avatars/avatar_5.png',
      initials: 'RD',
      intercations: '87',
      referrals_sent: '7',
      referrals_converted: '46'
    }
  ]
  });


mock.onGet('/api/dashboard/least-active-partners').reply(200, {
  partners: [
    {
      id: uuid(),
      color: colors.blueGrey['700'],
      name: 'Tim Tam',
      avatar: '/images/avatars/avatar_6.png',
      initials: 'GT',
      intercations: '3',
      last_login: '23/01/2020',
      referrals_sent: '53',
      referrals_converted: '77'
    },
    {
      id: uuid(),
      color: colors.cyan['500'],
      name: 'James Group',
      avatar: '/images/avatars/avatar_7.png',
      initials: 'TW',
      last_login: '02/03/2020',
      intercations: '0',
      referrals_sent: '39',
      referrals_converted: '53'
    },
    {
      id: uuid(),
      color: colors.indigo[600],
      name: 'Badder',
      avatar: '/images/avatars/avatar_8.png',
      initials: 'HN',
      last_login: '13/04/2020',
      intercations: '1',
      referrals_sent: '23',
      referrals_converted: '85'
    },
    {
      id: uuid(),
      color: colors.red['500'],
      name: 'Azura',
      avatar: '/images/avatars/avatar_9.png',
      initials: 'SO',
      last_login: '23/12/2019',
      intercations: '5',
      referrals_sent: '14',
      referrals_converted: '76'
    },
    {
      id: uuid(),
      color: colors.orange['900'],
      name: 'Lala',
      avatar: '/images/avatars/avatar_10.png',
      initials: 'RD',
      last_login: '23/02/2020',
      intercations: '3',
      referrals_sent: '7',
      referrals_converted: '46'
    }
  ]
});


mock.onGet('/api/dashboard/referralsstatus').reply(200, {
  earnings: [
    {
      id: uuid(),
      label: 'Open',
      value: 56,
      color: '#343b65'
    },
    {
      id: uuid(),
      label: 'Expired',
      value: 24,
      color: '#455087'
    },
    {
      id: uuid(),
      label: 'Executing',
      value: 20,
      color: '#7883ba'
    },
    {
      id: uuid(),
      label: 'Closed',
      value: 120,
      color: '#7883ba'
    }
  ]
});

mock.onGet('/api/dashboard/latest-referrals').reply(200, {
  referrals: [
    {
      id: uuid(),
      partner:
      {
        name: 'BCA Group',
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        avatar: '/images/avatars/avatar_1.png'
      },
      referred_entity:
      {
        contact_first_name: 'John',
        contact_last_name: 'Smith',
        contact_email: 'johnsmith@Palse.com.au',
        contact_phone: '0401123456',
        company: 'Palse'
      },
      status: 'new',
      opportunity: 
      {
        opportunity1: 'Book keeping',
        opportunity2: 'Accounting',
        opportunity3: 'SMSF'
      },
      commission:
      {
        value: '20% of profits',
        type: 'Gift Card'
      },
      date_created: '23/06/2020'
    },
    {
      id: uuid(),
      partner:
      {
        name: 'AZ Accounting',
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        avatar: '/images/avatars/avatar_2.png'
      },
      referred_entity:
      {
        contact_first_name: 'John',
        contact_last_name: 'Smith',
        contact_email: 'johnsmith@Palse.com.au',
        contact_phone: '0401123456',
        company: 'Palse'
      },
      status: 'Viewed',
      opportunity: 
      {
        opportunity1: 'Financial Planning',
        opportunity2: 'Accounting',
        opportunity3: 'SMSF'
      },
      commission:
      {
        value: '10% of revenue',
        type: 'Gift Card'
      },
      date_created: '23/06/2020'
    },
    {
      id: uuid(),
      partner:
      {
        name: 'Palse',
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        avatar: '/images/avatars/avatar_3.png'
      },
      referred_entity:
      {
        contact_first_name: 'John',
        contact_last_name: 'Smith',
        contact_email: 'johnsmith@Palse.com.au',
        contact_phone: '0401123456',
        company: 'Palse'
      },
      status: 'Viewed',
      opportunity: 
      {
        opportunity1: 'Accounting',
        opportunity3: 'SMSF'
      },
      commission:
      {
        value: '15% of profits',
        type: 'Gift Card'
      },
      date_created: '23/06/2020'
    },
    {
      id: uuid(),
      partner:
      {
        name: 'Hacker News',
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        avatar: '/images/avatars/avatar_4.png'
      },
      referred_entity:
      {
        contact_first_name: 'John',
        contact_last_name: 'Smith',
        contact_email: 'johnsmith@Palse.com.au',
        contact_phone: '0401123456',
        company: 'Palse'
      },
      status: 'new',
      opportunity: 
      {
        opportunity1: 'SMSF'
      },
      commission:
      {
        value: 'Reciprocal',
        type: 'Gift Card'
      },
      date_created: '23/06/2020'
    },
    {
      id: uuid(),
      partner:
      {
        name: 'BCA Group',
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        avatar: '/images/avatars/avatar_5.png'
      },
      referred_entity:
      {
        contact_first_name: 'John',
        contact_last_name: 'Smith',
        contact_email: 'johnsmith@Palse.com.au',
        contact_phone: '0401123456',
        company: 'Palse'
      },
      status: 'Deal Made',
      opportunity: 
      {
        opportunity1: 'Accounting',
        opportunity3: 'SMSF'
      },
      commission:
      {
        value: 'AUD100 on deal closed',
        type: 'Gift Card'
      },
      date_created: '23/06/2020'
    },
    {
      id: uuid(),
      partner:
      {
        name: 'Reddit',
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        avatar: '/images/avatars/avatar_6.png'
      },
      referred_entity:
      {
        contact_first_name: 'John',
        contact_last_name: 'Smith',
        contact_email: 'johnsmith@Palse.com.au',
        contact_phone: '0401123456',
        company: 'Palse'
      },
      status: 'Deal Made',
      opportunity: 
      {
        opportunity1: 'Financial Planning',
        opportunity2: 'Accounting',
        opportunity3: 'SMSF'
      },
      commission:
      {
        value: '10% of profits',
        type: 'Gift Card'
      },
      date_created: '23/06/2020'
    },
  ]
});

mock.onGet('/api/dashboard/industry-stats').reply(200, {
  industries: [
    {
      id: uuid(),
      name: 'Business Coaching',
      members: '123',
      referrals: '32',
      opportunities: '42',
      partnerships: '83',
      interactions: '317'
    },
    {
      id: uuid(),
      name: 'Accounting',
      members: '53',
      referrals: '2',
      opportunities: '2',
      partnerships: '43',
      interactions: '77'
    },
    {
      id: uuid(),
      name: 'Legal',
      members: '46',
      referrals: '6',
      opportunities: '8',
      partnerships: '31',
      interactions: '45'
    },
    {
      id: uuid(),
      name: 'Information Technology',
      members: '21',
      referrals: '9',
      opportunities: '3',
      partnerships: '11',
      interactions: '54'
    },
  ]
});