import { Add } from '@material-ui/icons'
import React from 'react'
import { makeStyles } from '@material-ui/styles';
import {
    Divider, Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    rooms: {
        // backgroundColor: '#fff',
        padding: '10px',

    },
    title: {
        marginBottom: 10,
    },
    roomsList: {
        marginLeft: 10,
    },
    addRooms: {
        display: 'flex',
        // alignItems: 'right',
        justifyContent: 'flex-end',
        borderRadius: 10,
        // paddingLeft: 50,
        marginTop: 10,
        '&:hover': {
            backgroundColor: '#80808059',
        }
    },
    btnText: {
        paddingTop: 2,
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    online: {
        width: '.5rem',
        height: '.5rem',
        backgroundColor: 'green',
        borderRadius: '50%',
        marginLeft: '10px'

    }
}));

function Rooms() {
    const classes = useStyles();
    return (
        <div className={classes.rooms}>
            <div className={classes.title}>
                <p>Rooms</p>
            </div>
            <div className={classes.roomsList}>
                <p style={{display: 'flex', alignItems: 'center'}}><u>Startup founders ARR  $5m</u> <span className={classes.online}></span></p>
                <p style={{display: 'flex', alignItems: 'center'}}><u>Seed fund raising</u>  <span className={classes.online}></span></p>
                <p style={{display: 'flex', alignItems: 'center'}}><u>Fintech hackathon</u>  <span className={classes.online}></span></p>
            </div>
            <Typography
                className={classes.addRooms}
                component={RouterLink}
                to={'/marketplace/members'}
            >
                <h5 className={classes.btnText}>Start a room</h5>
                <Add />
            </Typography>
            <Divider className={classes.divider} />
        </div>
    )
}

export default Rooms
