import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {useHistory} from 'react-router-dom'
// import Event from './Event';
import {makeStyles, Typography} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import { Link as RouterLink } from 'react-router-dom';
import EventComponent from 'src/componentsnew2/EventComponent'

const useStyles = makeStyles({
  UpcomingEvents: {
    marginTop: 10,
    padding: 10
  },
  addRooms: {
    display: 'flex',
    // alignItems: 'right',
    justifyContent: 'flex-end',
    borderRadius: 10,
    // paddingLeft: 50,
    marginTop: 10,
    '&:hover': {
        backgroundColor: '#80808059',
    }
},
btnText: {
    paddingTop: 2,
    fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    color: '#808091',
},
upcoming: {
  padding: 10,
  marginBottom: 10,
  borderRadius: 10,
  backgroundColor: '#fff',
  color: '#808091',
  boxShadow: `0px 4px 10px rgba(34, 36, 36, 0.30)`,

  fontFamily: 'Gilroy',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',

}

})


function UpcomingEvents() {
  const classes = useStyles();
    const [loading,setLoading] = useState(true)
  const jwtToken = localStorage.getItem('jwtToken') || '';
    const [event, setEvent] = useState()
    const [eventComplete, setEventComplete] = useState(false)
    const [filterEvent, setFiteredEvent] = useState([])
    
    const history = useHistory();
    const fetchPostsEvent = () => {
        setLoading(true)
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}api/event/GetEventPosts?pageSize=5&pageIndex=1`,
          headers: {
            'Authorization': 'Bearer ' + jwtToken
          }
        }).then((response) => {
          setEvent(response.data?.Posts);
          setEventComplete(true)
          setLoading(false)
        }, (error) => {
          console.log("GetFeedPosts error");
          console.log(error);
          // localStorage.setItem("postLoginPath", history.location.pathname)
          // error.response.status === 401 ? history.push('/auth/login') : window.alert("Oooops... An error has occured. Please contact support@bizforce.io. GetFeedPosts" + error);
    
        })
      };

      useEffect(() => {
        fetchPostsEvent()
        const filterArr =  event?.filter((array, index) => {
            if (index <= 1){
                return array
            }
        })
        setFiteredEvent(filterArr)
      }, [eventComplete])
 

    return (
        <div className={classes.UpcomingEvents}>
            <div>
            <div className={classes.upcoming}>Upcoming Events</div>
            {loading ? 
            <div style={{ display: 'grid', placeItems: 'center'}}>loading... </div>
            :
            <div> 
            {filterEvent?.map(events => 
             <div className={classes.event} key={events.id} 
            //  onClick={() => history.push('/marketplace/events/' + events.id)}
            >
                <EventComponent events={events} />
                </div>
            )}
            </div>
            }
            </div>
            <Typography
                className={classes.addRooms}
                component={RouterLink}
                to={'/marketplace/events'}
            >
                <h5 className={classes.btnText}>All events...</h5>
            </Typography>
        </div>
    )
}

export default UpcomingEvents
