import React from 'react';
import { useParams } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  // Toolbar,
  // Input,
  // IconButton,
  // Tooltip,
  Button,
  CardActions,
  Divider,
  CardHeader,
  List,
  colors
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
// import SearchIcon from '@material-ui/icons/Search';
import ConversationListItem from './ConversationListItem';
// import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    height: '20rem',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white
  },
  searchInput: {
    flexGrow: 1
  },
  conversationList: {
    'overflow-y': 'auto'
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: 'center'
  }
}));

function ConversationList({
  conversations,
  onSelectConversation,
  className,
  CloseConversationList,
  ...rest
}) {
  const classes = useStyles();
  const params = useParams();
  const selectedConversation = params.id;
  // const history = useHistory();
  const styles = { targetDiv: { height: 'calc(70vh - 65px)' } };

  const handleSelectConversation = (event, conversation) => {
    onSelectConversation(conversation);
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, classes.conversationList, className)}
      style={styles.targetDiv}
    >
      {/* <Toolbar>
        <Input
          className={classes.searchInput}
          disableUnderline
          placeholder="Search contacts"
        />
        <Tooltip title="Search">
          <IconButton edge="end">
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <Divider /> */}
              <CardHeader title="Conversations" />
        <Divider />

      <List disablePadding>
        {conversations.map((conversation, i) => {
          return (
            <ConversationListItem
              active={
                selectedConversation !== null &&
                Number(conversation.conversation_id) ===
                  Number(selectedConversation)
              }
              conversation={conversation}
              divider={i < conversations.length - 1}
              onClick={(event) => {
                handleSelectConversation(event, conversation)
                CloseConversationList()
              }}
              key={conversation.conversation_id}
            />
          );
        })}
      </List>
      <Divider />
        <CardActions className={classes.actions}>
          <Button
            component={RouterLink}
            size="large"
            to="/chat"
          >
            See all
          </Button>
        </CardActions>

    </div>
  );
}

ConversationList.propTypes = {
  className: PropTypes.string,
  onSelectConversation: PropTypes.func,
  conversations: PropTypes.array.isRequired
};

export default ConversationList;
