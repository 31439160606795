import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  // Card,
  CardContent,
  // CardHeader,
  // Divider,
  // LinearProgress,
  // Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  // colors,
  makeStyles,
  Tooltip,
  IconButton
} from '@material-ui/core';
import {
  UserPlus as UserPlusIcon,
  Award as AwardIcon,
  MessageSquare as MessageSquareIcon,
  Briefcase as BriefcaseIcon
} from 'react-feather';
// import MailOutlineIcon from '@material-ui/icons/MailOutline';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import { Link as RouterLink } from 'react-router-dom';
import isEqual from 'src/utils/isEqual';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activityProgress: {
    width: '100%',
    background: '#FFFFFF',
    boxShadow: `0px 4px 10px rgba(34, 36, 36, 0.30)`,
    borderRadius: 10,
    marginBottom: 10,
    fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    padding: 10,
    color: '#808091',
    ['@media (max-width: 500px)']: {
      width: '97vw',
      marginLeft: 5,
    }
  },
  content: {
    paddingTop: 0,
    // width: 291,
    height: 314,
    background: '#FFFFFF',
    boxShadow: `0px 4px 10px rgba(34, 36, 36, 0.30)`,
    borderRadius: 10,
    ['@media (max-width: 700px)']: {
      height: 180,
    },
  },
  contentText: {
    fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '16px',
    color: '#23205B',
    marginLeft: -8,
  },
  jobAvatar: {
    // backgroundColor: theme.palette.primary.main
  },
  count: {
    color: '#fff',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    height: 30,
    width: 30,
    display: 'grid',
    placeItems: 'center'
  },
  lists: {
    ['@media (max-width: 700px)']: {
      display: 'grid',
      gridTemplateColumns: ' repeat(2, 70%)',
      width: 346,
      height: 108,
      background: '#FFFFFF',
      // boxShadow: '0px 4px 10px rgb(34, 36, 36, 0.3%)',
      borderRadius: 10,

    },
    [`@media (max-width: 540px)`]: {
      gridTemplateColumns: ' repeat(2, 70%)',
    },
    [`@media (max-width: 500px)`]: {
      gridTemplateColumns: ' repeat(2, 55%)',

    },

    [`@media (max-width: 400px)`]: {
      gridTemplateColumns: ' repeat(2, 50%)',
    }
  },
  unreadCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
}));


function About({
  className,
  // user,
  ...rest
}) {
  const classes = useStyles();
  let jwtToken = localStorage.getItem('jwtToken') || '';
  const axios = require('axios');
  const [unreadCount, setUnreadCount] = useState({
    incoming_referral_count: 0,
    incoming_partnership_count: 0,
    outgoing_partnership_count: 0
  });

  const getData = async () => {

    const notifications = localStorage.getItem("userNotifications") && JSON.parse(localStorage.getItem("userNotifications"))
    if (notifications) {
      setUnreadCount(notifications)
    }
    else {
      const res = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}api/User/GetUserNotificationsCount`,
        headers: {
          Authorization: 'Bearer ' + jwtToken
        }
      });
      if (!isEqual(res.data[0], unreadCount)) {
        localStorage.setItem('userNotifications', JSON.stringify(res.data[0]))
        setUnreadCount(res.data[0]);
      }
    }

    setTimeout(() => {
      jwtToken = localStorage.getItem('jwtToken') || '';
      getData()
    }, 20000);
  };

  useEffect(() => {
    if (jwtToken !== '') {
      getData();
    }
  }, []);

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box mt={1}>
        <h3 className={classes.activityProgress}>Activity Center</h3>
        <div style={{ height: '100%' }}>
          {/* <CardHeader title="Activity Center" /> */}
          {/* <Divider /> */}
          <CardContent className={classes.content}>
            {/* <LinearProgress
              variant="determinate"
              value={50}
            />
            <Box mt={2}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
              >
                50% of activities completed
            </Typography>
            </Box> */}
            <List className={classes.lists}>

              {/* //From this line */}
              <ListItem
                disableGutters
                divider
                component={RouterLink}
                to={'/marketplace/members'}
                style={{
                  display: 'flex',
                  alignTtems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <ListItemAvatar>
                  <Avatar className={classes.jobAvatar}>
                    <UserPlusIcon size="22" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={(
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.contentText}
                    >
                      Connect with others
                    </Typography>
                  )}
                // secondary={(
                //   <Typography
                //     variant="caption"
                //     color="textSecondary"
                //   >
                //     Start conversations & send invites to other members
                //   </Typography>
                // )}
                />
                <ListItemSecondaryAction>
                  <Tooltip title={<span style={{ fontSize: '14px' }}>Start conversations & send invites to other members</span>}>
                    <IconButton
                      edge="end"
                      size="small"
                      component={RouterLink}
                      to={'/marketplace/members'}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem
                disableGutters
                divider
                component={RouterLink}
                to={'/my-projects'}
                style={{
                  display: 'flex',
                  alignTtems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <ListItemAvatar>
                  <Avatar className={classes.jobAvatar}>
                    <BriefcaseIcon size="22" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={(
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.contentText}
                    >
                      My opportunities
                    </Typography>
                  )}
                // secondary={(
                //   <Typography
                //     variant="caption"
                //     color="textSecondary"
                //   >
                //     Start conversations & send invites to other members
                //   </Typography>
                // )}
                />
                <ListItemSecondaryAction>
                  <Tooltip title={<span style={{ fontSize: '14px' }}>Start conversations & send invites to other members</span>}>
                    <IconButton
                      edge="end"
                      size="small"
                      component={RouterLink}
                      to={'/my-projects'}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem
                disableGutters
                divider
                className={classes.unreadCount}
                component={RouterLink}
                to={'/partners/outbound'}
                style={{
                  display: 'flex',
                  alignTtems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <ListItemAvatar>
                  <Avatar className={classes.jobAvatar}>
                    <GroupAddIcon size="22" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={(
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.contentText}
                    >
                      My network
                    </Typography>
                  )}
                />
                {/* <ListItemText
                  disableTypography
                  primary={(
                    <Typography
                      variant="body1"
                      color="textPrimary"
                    >
                      {unreadCount?.incoming_partnership_count}
                    </Typography>
                  )}
                /> */}
                <ListItemSecondaryAction>
                  {/* <Tooltip title={<span style={{ fontSize: '14px' }}>See who invited you to connect</span>}> */}
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    component={RouterLink}
                    to={'/partners/outbound'}

                  >
                    {unreadCount && unreadCount?.incoming_partnership_count > 0 ?
                      <div className={classes.count}>
                        {unreadCount && unreadCount?.incoming_partnership_count + " "}
                      </div> :

                      <IconButton
                        edge="end"
                        size="small"
                      >
                        <ArrowForwardIcon />
                      </IconButton>}
                  </Typography>
                  {/* </Tooltip> */}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem
                disableGutters
                divider
                className={classes.unreadCount}
                component={RouterLink}
                to={'/referrals/inbound'}
                style={{
                  display: 'flex',
                  alignTtems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <ListItemAvatar>
                  <Avatar className={classes.jobAvatar}>
                    <PersonAddIcon size="22" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={(
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.contentText}
                      component={RouterLink}
                      to={'/referrals/inbound'}
                    >
                      Referrals
                    </Typography>
                  )}
                />
                {/* <ListItemText
                  disableTypography
                  primary={(
                    <Typography
                      variant="body1"
                      color="textPrimary"
                    >
                      {unreadCount?.incoming_referral_count}
                    </Typography>
                  )}
                /> */}
                <ListItemSecondaryAction>
                  <Tooltip title={<span style={{ fontSize: '14px' }}>Check your incoming referrals inbox</span>}>
                    <Typography
                      variant="body1"
                      color="textPrimary"

                    >
                      {unreadCount && unreadCount?.incoming_referral_count > 0 ?
                        <div className={classes.count}>
                          {unreadCount && unreadCount?.incoming_referral_count + " "}
                        </div> :
                        <IconButton
                          edge="end"
                          size="small"
                          component={RouterLink}
                          to={'/referrals/inbound'}
                        >
                          <ArrowForwardIcon />
                        </IconButton>
                      }
                    </Typography>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
              {/* <ListItem
                disableGutters
                divider
                className={classes.unreadCount}
                component={RouterLink}
                to={'/chat'}
              >
                <ListItemAvatar>
                  <Avatar className={classes.jobAvatar}>
                    <MailOutlineIcon size="22" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={(
                    <Typography
                      variant="body2"
                      color="textPrimary"
                    >
                      Unread messages
                    </Typography>
                  )}
                />
                <ListItemSecondaryAction>
                  <Tooltip title={<span style={{ fontSize: '14px' }}>Open conversations</span>}>
                  <Typography
                      variant="body1"
                      color="textPrimary"
                    >
                      {unreadCount?.messenger_messages_count+" "}
                    <IconButton
                      edge="end"
                      size="small"
                      component={RouterLink}
                      to={'/chat'}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                    </Typography>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem> */}
              <ListItem
                disableGutters
                divider
                component={RouterLink}
                to={'/profile'}
                style={{
                  display: 'flex',
                  alignTtems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <ListItemAvatar>
                  <Avatar className={classes.jobAvatar}>
                    <AwardIcon size="22" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={(
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.contentText}
                    >
                      Update my profile
                    </Typography>
                  )}
                />
                <ListItemSecondaryAction>
                  <Tooltip title={<span style={{ fontSize: '14px' }}>Edit your profile</span>}>
                    <IconButton
                      edge="end"
                      size="small"
                      component={RouterLink}
                      to={'/profile'}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
              {/* <ListItem
                disableGutters
                divider
                component={RouterLink}
                to={"/board"}
              >
                <ListItemAvatar>
                  <Avatar className={classes.jobAvatar}>
                    <BriefcaseIcon size="22" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={(
                    <Typography
                      variant="body2"
                      color="textPrimary"
                    >
                      Post an opportunity
                    </Typography>
                  )}
                />
                <ListItemSecondaryAction>
                  <Tooltip title={<span style={{ fontSize: '14px' }}>Post an opportunity</span>}>
                    <IconButton
                      edge="end"
                      size="small"
                      component={RouterLink}
                      to={"/board"}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem> */}
              {/* <ListItem
                disableGutters
                divider
                component={RouterLink}
                to={"/feed"}
              >
                <ListItemAvatar>
                  <Avatar className={classes.jobAvatar}>
                    <MessageSquareIcon size="22" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={(
                    <Typography
                      variant="body2"
                      color="textPrimary"
                    >
                      Start a discussion
                    </Typography>
                  )}
                />
                <ListItemSecondaryAction>
                  <Tooltip title={<span style={{ fontSize: '14px' }}>Post something on the feed</span>}>
                    <IconButton
                      edge="end"
                      size="small"
                      component={RouterLink}
                      to={"/feed"}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem> */}
              {/* <ListItem>
              <div className={classes.unreadCount}>
              <h2>New referals</h2>
              <p>2</p>
              </div>
              </ListItem>
              <ListItem>
              <div className={classes.unreadCount}>
              <h2>New referals</h2>
              <p>2</p>
              </div>
              </ListItem><ListItem>
              <div className={classes.unreadCount}>
              <h2>New referals</h2>
              <p>2</p>
              </div>
              </ListItem> */}
            </List>
          </CardContent>
        </div>
      </Box>
    </div>
  );
}

About.propTypes = {
  className: PropTypes.string,
  // user: PropTypes.object.isRequired
};

export default About;
