import { Avatar, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
// import CardComponent from 'src/componentsnew2/CardComponent'
// import Popup from 'src/componentsnew2/PopupComponent'
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        // cursor: 'pointer',
      justifyContent: 'space-between',
        background: '#FFFFFF',
        // width: 185,
        height: 100,
        marginBottom: 20,
        boxShadow: `0px 4px 10px rgba(34, 36, 36, 0.30)`,
        padding: '10px',
        borderRadius: 10,
        '&:hover': {
            border: `1px solid #4144FF`
        }

    },
    cardLeft: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        // marginRight: 20,
        flex: '0.3'
    },
    cardRight: {
        display: 'flex',
        flexDirection: 'column',
       justifyContent: 'space-around',
        color: '#51556e',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        flex: '0.7'
    },
    btn: {
        border: 'none',
        padding: 4,
        background: theme.palette.primary.main,
        borderRadius: 4,
        color: '#fff',
        marginTop: 5,
        width: '88px',
        height: '30px',
        cursor: 'pointer',
        fontSize: '0.6rem',
        "&:hover": {
            background: theme.palette.primary.main,
        }

    },
    btnView: {
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        "&:hover": {
            background: '#FFF',
        }
    },
    avatar: {
        width: 46,
        height: 46,
    },
    members: {
        marginTop: 15,
        display: 'flex',
        // alignItems: 'center',
        // padding: '10px',
        cursor: 'pointer',
        borderRadius: 10,
        '&:hover': {
            backgroundColor: '#80808059'
        }
    }
}));


function Members({ members }) {
    const classes = useStyles()
    const history = useHistory();
    const [companyProfile] = useState({
        user_id: members.user_id,
        avatar: members.company_contact_avatar,
        company_contact_first_name: members.company_contact_first_name,
        company_contact_last_name: members.company_contact_last_name,
        bio: members.company_bio,
        company_name: members.company_name,
        company_avatar: members.company_contact_avatar || members.company_avatar
    });

    const handleMessage = () => {
        localStorage.setItem("ConversationCompany", JSON.stringify(companyProfile));
    };


    return (
        <div>
            <div className={classes.card} >
                <div className={classes.cardLeft}>
                    <Avatar className={classes.avatar} src={members.company_avatar && members.company_avatar} />
                    <Button className={[classes.btn, classes.btnView]} onClick={() => history.push(`/partner/${members.user_id}`)}>View</Button>
                </div>
                <div className={classes.cardRight}>
                    <h4 style={{ color: '#51556e', fontSize: '0.8rem', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{members.company_contact_first_name && (members.company_contact_first_name + " " + members.company_contact_last_name)}</h4>
                    <h4 style={{ color: '#7f8190', fontSize: '0.8rem', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{members.company_name && members.company_name}</h4>
                    <div style={{display: 'flex', justifyContent: 'flex-end', paddingRight: '15px', width: '100%'}}>
                        <Button
                            className={classes.btn}
                            // href={"/Chat/" + (members.conversation_id ? members.conversation_id : "-1")}
                            component={RouterLink}
                            to={"/Chat/" + (members.conversation_id ? members.conversation_id : "-1")}
                            onClick={handleMessage}>
                            Message
                        </Button>
                    </div>
                </div>
            </div>

            {/* <CardComponent href={"/Chat/"+members.conversation_id} handleMessage={handleMessage} route={() => history.push(`/partner/${members.user_id}`)}  members={members}/> */}
        </div>
    )
}

export default Members
