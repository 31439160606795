import { colors } from '@material-ui/core';
// import { useSelector } from 'react-redux';

let primaryMain;
if(!localStorage.getItem("TenantDetail")) {
  primaryMain = '#232c66'
} else {
  primaryMain = JSON.parse(localStorage.getItem("TenantDetail"))["primary_color"] || '#232c66';
}

const white = '#FFFFFF';

export default {
  primary: {
    contrastText: white,
    // dark: colors.indigo[900],
    // main: colors.indigo[500],
    // light: colors.indigo[100]
    dark: '#1a1e32',
    main: primaryMain,
    // main: session.primaryColor,
    fontFamily: 'Gilroy',
    light: '#7883ba'
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue.A700,
    light: colors.blue.A400
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.orange[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    defaul: '#e2e2e2',
    // default: '#F4F6F8',
    paper: white
  },
  divider: colors.grey[200]
};
