import React, { useState, useEffect } from 'react'
import { makeStyles, Button, Avatar, CardMedia} from '@material-ui/core';
import moment from 'moment-timezone'
import { Link as RouterLink } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        // cursor: 'pointer',
        background: '#FFFFFF',
        justifyContent: 'space-between',
        // width: 185,
        height: 100,
        marginBottom: 20,
        boxShadow: `0px 4px 10px rgba(34, 36, 36, 0.30)`,
        padding: '10px',
        borderRadius: 10,
        '&:hover': {
            border: `1px solid #4144FF`

        }

    },
    cardLeft: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        // marginRight: 20,
        flex: '0.3'
    },
    cardRight: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        color: '#51556e',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        flex: '0.7'

    },
    btn: {
        border: 'none',
        padding: 4,
        background: theme.palette.primary.main,
        borderRadius: 4,
        color: '#fff',
        marginTop: 5,
        width: '88px',
        height: '30px',
        cursor: 'pointer',
        fontSize: '0.6rem',
        "&:hover": {
            background: theme.palette.primary.main,
        }

    },
    btnView: {
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        "&:hover": {
            background: '#FFF',
        }
    },
    avatar: {
        width: 106,
        height: 86,
        marginRight: "10px",
        paddingTop: '67.25%', // 16:9
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        borderRadius: 10,
        // margin: '0px 10px',
        },
}));

function EventComponent({ route, href, handleMessage, members, events }) {
    const classes = useStyles()
    events.event_time = moment.tz(events.event_time, "Australia/Sydney");
    const [location, setLocation] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    return (
        <div className={classes.card}>
            <div className={classes.cardLeft}>
                <CardMedia 
                    className={classes.avatar}
                    component={RouterLink}
                    to={'/marketplace/events/' + events.id}
                    image={events.url || 'https://bizforce-prod-assets.s3-ap-southeast-2.amazonaws.com/general-assets/projectBoard_default.jpg'} />
            </div>
            <div className={classes.cardRight}>
                <h4 style={{ color: '#51556e', fontSize: '0.8rem', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{!events ? members.company_contact_first_name + " " + members.company_contact_last_name : events.title}</h4>
                {/* <h4 style={{color: '#7f8190', fontSize: '0.8rem', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', }}>{events.event_time ? moment.tz(events.event_time, location).format('MMMM Do')+" at " +moment.tz(events.event_time, location).format('MMMM Do') :""}</h4> */}
                <h4 style={{ color: '#7f8190', fontSize: '0.8rem', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{events.event_time ? (moment(events.event_time).tz(location).format('MMMM Do') + " " + moment(events.event_time).tz(location).format('hh:mma z')) : ""}</h4>
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '15px', width: '100%' }}>
                    <Button
                        className={classes.btn}
                        component={RouterLink}
                        to={'/marketplace/events/' + events.id}
                    //  onClick={handleMessage}
                    >{events.event_link ?"Register now":"View"}</Button>
                </div>
            </div>
        </div>
    )
}

export default EventComponent
