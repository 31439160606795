import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';

Sentry.init({dsn: "https://ceec16fffc17414399e391ace5bdc4f9@o423842.ingest.sentry.io/5354757"});

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
