import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link, Avatar, colors } from '@material-ui/core';
import Linkify from 'react-linkify';
import rtlCheck from 'src/utils/rtlCheck';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  authUser: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& $body': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  },
  inner: {
    display: 'flex',
    maxWidth: 500,
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  body: {
    backgroundColor: colors.grey[100],
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2)
  },
  content: {
    marginTop: theme.spacing(1),
    minWidth: 200

  },
  image: {
    marginTop: theme.spacing(2),
    height: 'auto',
    width: 380,
    maxWidth: '100%'
  },
  footer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  referralLink: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
    // color: '#b7bdbd'
  },
  displayAuthorLinebreak: {
    "cursor": "auto !important",
    "white-space": "pre-line",
    wordBreak: 'break-all',
    '& $a': {
      color: '#FFFBE5 !important',
      'text-decoration': 'underline',
      'font-style': 'italic',
      'cursor': 'pointer'
    }
  },
  displayReceipientLinebreak: {
    "cursor": "auto !important",
    "white-space": "pre-line"
  },
}));

function ConversationMessage({ message, className, ...rest }) {
  const classes = useStyles();
  const jwtToken = localStorage.getItem('jwtToken') || '';
  const [authUser, setAuthUser] = useState({});
  useEffect(() => {
    const base64Url = jwtToken.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    setAuthUser(JSON.parse(window.atob(base64)));
  }, []);

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  return (
    <div
      {...rest}
      className={clsx(
        classes.root,
        {
          [classes.authUser]: message.message_user_id == authUser.UserID
        },
        className
      )}

    // href={message.referral_link ? message.referral_link : null}

    >
      <div className={classes.inner}>
        <Avatar
          className={classes.avatar}
          // component={RouterLink}
          src={message.message_avatar}
        // to="/profile/1/timeline"
        />
        <div>
          <div className={classes.body}>
            <div>
              <Link
                color="inherit"
                // component={RouterLink}
                // to="/profile/1/timeline"
                variant="h6"
              >
                {message.message_user_id == authUser.UserID ? 'Me' : (message.message_last_name ? message.message_last_name : '') + ' ' + (message.message_last_name1 ? message.message_last_name1 : '')}
              </Link>
            </div>
            <div className={classes.content}>
              {message.contentType === 'image' ? (
                <img
                  alt="Attachment"
                  className={classes.image}
                  src={message.message_content}
                />
              ) : (
                  <Linkify componentDecorator={componentDecorator} color="inherit">
                    <Typography
                      style={{ direction: rtlCheck(message.message_content) ? 'rtl' : 'ltr' }}
                      variant="body1"
                      color="inherit"
                      className={message.message_user_id == authUser.UserID ? classes.displayAuthorLinebreak : classes.displayReceipientLinebreak}
                    >
                      {message.message_content.substring(0, message.referral_link ? 50 : message.message_content.length)}
                      {message.referral_link && '...'}
                    </Typography>
                  </Linkify>


                )}

              {message.referral_link &&
                <>
                  <Typography
                    style={{ color: message.message_user_id == authUser.UserID ? '#b7bdbd' : 'grey', direction: rtlCheck(message.message_content) ? 'rtl' : 'ltr' }}
                    component={RouterLink}
                    to={message.referral_link}
                    variant="body1"
                    color="primary"
                    className={classes.referralLink}
                  >
                    This message was send from the referral conversation. Click below to view the full conversation
                  </Typography>
                  <PersonAddIcon
                    style={{ color: message.message_user_id == authUser.UserID ? '#a2c8ca' : 'cadetblue', marginRight: '8px', marginBottom: '-8px' }}
                    size="22" />
                  <Link
                    style={{ color: message.message_user_id == authUser.UserID ? '#a2c8ca' : 'cadetblue' , direction: rtlCheck(message.message_content) ? 'rtl' : 'ltr' }}
                    variant="body2"
                    underline="always"
                    href={message.referral_link}
                    color="inherit"
                    className={message.message_user_id == authUser.UserID ? classes.displayAuthorLinebreak : classes.displayReceipientLinebreak}
                  >
                    OPEN REFERRAL CONVERSATION
                  </Link>
                </>
              }

            </div>
          </div>
          <div className={classes.footer}>
            <Typography
              className={classes.time}
              variant="body2"
            >
              {moment(message.message_time).fromNow()}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

ConversationMessage.propTypes = {
  className: PropTypes.string,
  message: PropTypes.object.isRequired
};

export default ConversationMessage;
