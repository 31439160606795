import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  CardActionArea,
  Link,
  Tooltip,
  Typography,
  CardMedia,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Popover
} from '@material-ui/core';
// import ShareIcon from '@material-ui/icons/Share';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import getInitials from 'src/utils/getInitials';
import Label from 'src/components/Label';
// import CommentBubble from './CommentBubble';
// import CommentForm from './CommentForm';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Linkify from 'react-linkify';
import { useHistory } from 'react-router';
import jwt from 'jwt-decode';
import axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import { transform } from 'lodash';
// import { VerticalAlignCenter } from '@material-ui/icons';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import img from 'src/assets/img/black_learn.png'
import ReactPlayer from 'react-player'


const useStyles = makeStyles(theme => ({
  root: {
    // border: '1px solid ' + theme.palette.primary.main,
    // width: 306,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 10,
    width: '100%',
  },
  header: {
    paddingBottom: 0,
    padding: 10,
    marginBottom: 10,
  },
  content: {
    padding: 0,

    '&:last-child': {
      paddingBottom: 0
    }
  },
  description: {
    // marginBottom: theme.spacing(2)
    padding: theme.spacing(2, 2, 2, 2)
  },
  tags: {
    padding: theme.spacing(0, 3, 2, 3),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  learnMoreButton: {
    marginLeft: theme.spacing(2)
  },
  likedButton: {
    color: colors.red[600]
  },
  shareButton: {
    marginLeft: theme.spacing(1)
  },
  mediaArea: {
    width: '100%'
  },
  media: {
    // height: 200,
    // width: 306,
    // width: '100%',
    height: 187,
    filter: 'drop-shadow(0px 4px 10px rgba(34, 36, 36, 0.03))',
    borderRadius: 10,
    // paddingTop: '67.25%',
    backgroundColor: theme.palette.background.dark,
    backgroundSize: 'contain'
  },
  file: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  title: {
    cursor: 'auto !important',
    'white-space': 'pre-line',
    fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '21px',
    color: '#23205B',
    // marginTop: 10,
    '& $a': {
      color: '#3366BB !important',
      'text-decoration': 'underline',
      'font-style': 'italic',
      cursor: 'pointer'
    }
  },
  message: {
    cursor: 'auto !important',
    'white-space': 'pre-line',
    // fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '21px',
    color: '#23205B',
    marginTop: 10,
    '& $a': {
      color: '#3366BB !important',
      'text-decoration': 'underline',
      'font-style': 'italic',
      cursor: 'pointer'
    }
  },
  markAsLibrary: {
    marginRight: theme.spacing(1)
  },
  select: {
    width: '25px',
    height: '35px',
    bottom: '10px'
  },
  // media: {
  //   paddingTop: '67.25%',
  //   backgroundPosition: 'initial'
  // },
  details: {
    padding: theme.spacing(2, 3)
  },
  no_image: {
    width: '100%',
    fontSize: '24px',
    // border: '1px solid ' + theme.palette.primary.main
  },
  placeholder: {
    position: 'absolute',
    right: '50%',
    bottom: '50%',
    transform: 'translate(50%, 50%)'
  },
  successPopover: {
    padding: 24,
    backgroundColor: 'green'
  },
  popoverText: {
    color: 'white'
  }
}));

function LibraryCard({
  post,
  className,
  onCommentCompleted,
  onOpenImage,
  onDeleteMessageCompleted,
  anchorEl,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();
  const jwtToken = localStorage.getItem('jwtToken') || '';
  const user = jwt(jwtToken);
  const handleCommentCompeted = () => {
    onCommentCompleted();
  };
  const [priority, setPriority] = useState('');
  const priorities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 'REMOVE'];
  const defaultChars = 50;
  const [showChars, setShowChars] = useState(defaultChars);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(post.title);
  const [message, setMessage] = useState(post.message);
  const [link, setLink] = useState('');
  const [popover, setPopover] = useState(false);
  const [isDelete, setIsDelete] = useState(false)
  const [successPopover, setSuccessPopover] = useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // const [liked, setLiked] = useState(post.liked);

  // const handleLike = () => {
  //   setLiked(true);
  // };

  // const handleUnlike = () => {
  //   setLiked(false);
  // };
  const handleEditChange = event => {
    const value = {};
    if (event.target.name === 'title') {
      setTitle(event.target.value);
    } else {
      setMessage(event.target.value);
    }
  };

  const handleDeletePost = () => {
    setIsDelete(true)
  };

  const deletePost = item => {
    if (post != null) {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}api/user/DeleteFeedPost/` +
          post.id,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + jwtToken
            }
          }
        )
        .then(
          response => {
            onDeleteMessageCompleted();
            setIsDelete(false)
          },
          error => {
            console.log('delete message error');
            console.log(error);
            setIsDelete(false)
            localStorage.setItem('postLoginPath', '/marketplace/resources');
            if (error.response != null) {
              error.response != null && error.response.status === 401
                ? history.push('/auth/login')
                : window.alert(
                  'Oooops... An error has occured. Please contact support@bizforce.io. ' +
                  error
                );
            }
          }
        );
    }
  }

  const handleOpenImage = url => {
    if (link) {
      window.open(link.includes("http") ? link : ("http://" + link), "_blank")
    }
    else {
      onOpenImage(url);
    }
  };

  useEffect(() => {
    if (post && post.message) {
      let message = post.message.split(/\s+/)
      const links = []
      message.forEach(el => {
        if (el.indexOf('http://') === 0 || el.indexOf('https://') === 0) {
          links.push(el)
        }
      })
      setLink(links[0])
    }
  }, []);

  const handleEdit = () => {
    let mounted = true;

    let requestData = '';
    post.id && (requestData = requestData + 'Id=' + post.id);
    requestData = requestData + '&Message=' + message;
    requestData = requestData + '&Title=' + title;

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}api/user/EditLibraryPost`,
      headers: {
        Authorization: 'Bearer ' + jwtToken
      },
      data: requestData
    }).then(
      response => {
        if (mounted) {
          onCommentCompleted();
        }
      },
      error => {
        console.log('MarkAsLibraryPost error');
        console.log(error);
        localStorage.setItem('postLoginPath', '/marketplace');
        error.response != null && error.response.status === 401
          ? history.push('/auth/login')
          : window.alert(
            'Oooops... An error has occured. Please contact support@bizforce.io. MarkAsLibraryPost' +
            error
          );
      }
    );
    handleClose();
  };

  const handleChange = event => {
    setPriority(event.target.value);

    let libraryItem = '';
    let mounted = true;

    if (event.target.value === 'REMOVE') {
      libraryItem = '';
    } else {
      libraryItem = event.target.value;
    }
    let requestData = '';
    post.id && (requestData = requestData + 'Id=' + post.id);
    requestData = requestData + '&LibraryItem=' + libraryItem;

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}api/user/MarkAsLibraryPost`,
      headers: {
        Authorization: 'Bearer ' + jwtToken
      },
      data: requestData
    }).then(
      response => {
        setPopover(true)
        if (mounted) {

        }
      },
      error => {
        console.log('MarkAsLibraryPost error');
        console.log(error);
        localStorage.setItem('postLoginPath', '/marketplace');
        error.response != null && error.response.status === 401
          ? history.push('/auth/login')
          : window.alert(
            'Oooops... An error has occured. Please contact support@bizforce.io. MarkAsLibraryPost' +
            error
          );
      }
    );
  };

  const showMore = () => {
    if (post.message && post.message.length > 100)
      history.push('/marketplace/resources/' + post.id);
    else
      setShowChars(post.message && post.message.length);
  };

  const showLess = () => {
    setShowChars(defaultChars);
  };

  const openFile = () => {
    window.open(post.url, '_blank')
  }


  const windowReload = () => {
    setSuccessPopover(true)
  }

  // useEffect(() => {
  //   if (successPopover) {
  //     setTimeout(() => {
  //       setSuccessPopover(false)
  //     }, 3000)
  //     window.location.reload()
  //   }
  // }, []);

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  return (
    <Card {...rest} className={clsx(classes.root, className)} >
      {/* <div onClick={() => history.push(`/marketplace/resources/${post.id}`)}> */}
      <div>
        <Popover
          open={successPopover}
          anchorEl={anchorEl}
          onClose={() => setSuccessPopover(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={classes.successPopover}>
            <span className={classes.popoverText}>
              Success!
                      </span>
          </div>
        </Popover>

        {post.file_name && post.file_name.match(/.(jpg|jpeg|bmp|png|jfif|gif)$/i) ? (
          <CardActionArea className={classes.mediaArea}>
            <CardMedia
              className={classes.media}
              image={post.url}
              onClick={() => handleOpenImage(post.url)}
            />
          </CardActionArea>
        ) : (
            post.file_name && (
              <CardActionArea className={classes.no_image} onClick={openFile} >
                <Typography align="center" className={classes.media}>
                  <Link
                    href={post.url}
                    target="_blank"
                    className={classes.placeholder}
                  >
                    <AttachFileOutlinedIcon /> {post.file_name}
                    <Button color="grey" variant="contained">
                      Open file
                    </Button>
                  </Link>
                </Typography>
              </CardActionArea>
            )
          )}
        {!post.file_name && !link && (
          <CardActionArea disabled className={classes.mediaArea}>
            <CardMedia
              className={classes.media}
              image={img}
            />
          </CardActionArea>
        )}

        {!post.file_name && link && (
          <CardActionArea className={classes.no_image}>


            {(link.indexOf('youtu.be') > -1 || link.indexOf('youtube.com') > -1 || link.indexOf('vimeo.com') > -1) ?
              (<CardMedia
                className={classes.media}>
                <ReactPlayer width="100%" height="100%" url={link} config={{
                  youtube: {
                    playerVars: {
                      showinfo: 1,
                      controls: 2
                    }
                  }
                }} />
              </CardMedia>) :



              <CardMedia
                className={classes.media}
                image={img}
                onClick={() => handleOpenImage(post.url)}
              />}
            {/* <LinkPreview
              url={link}
            /> */}
          </CardActionArea>
        )}
        <CardContent className={classes.content}>
          <div className={classes.description}>
            <Typography
              color="textSecondary"
              variant="subtitle2"
              className={classes.title}
            >
              {post.title && post.title.substring(0, 50)}
            </Typography>
            <Linkify componentDecorator={componentDecorator}>
              {/* {link && post.file_name && (
                <LinkPreview
                  url={link}
                />
              )} */}
              <Typography
                color="textSecondary"
                variant="subtitle2"
                className={classes.message}
              >
                {post.message && post.message.substring(0, showChars)}
              </Typography>
              <Typography
                color="textSecondary"
                variant="subtitle2"
                className={classes.message}
              >
                {post.message && post.message.length > showChars && (
                  <Link onClick={showMore} style={{ marginTop: '30px', color: '#2962FF', zIndex: '1000' }}>
                    <br />
                  ...Read More
                  </Link>
                )}
                {showChars > defaultChars && (
                  <Link style={{ marginTop: '30px', color: '#2962FF', zIndex: '1000' }} onClick={showLess}>
                    <br />
                  ...Show Less
                  </Link>
                )}
              </Typography>
            </Linkify>

            {(post.sent_by_user_id == user.UserID
            || user['EcosystemRole'] == 'Admin'
          ) && (
              <Tooltip
                placement="top"
                title={
                  <span style={{ fontSize: '14px' }}>Edit post</span>
                }
              >
                <EditOutlinedIcon
                  className={classes.deleteButton}
                  onClick={() => handleClickOpen(post)}
                />
              </Tooltip>
            )}
          {(post.sent_by_user_id == user.UserID
            || user['EcosystemRole'] == 'Admin'
          ) && (
              <Tooltip
                placement="top"
                title={
                  <span style={{ fontSize: '14px' }}>Delete post</span>
                }
              >
                <DeleteOutlinedIcon
                  className={classes.deleteButton}
                  onClick={handleDeletePost}
                />
              </Tooltip>
            )}


          </div>

          <Dialog
            open={isDelete}
            onClose={() => setIsDelete(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you wish to delete this post?
          </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => deletePost(post)} color="primary">
                Yes
          </Button>
              <Button onClick={() => setIsDelete(false)} color="primary" autoFocus>
                No
          </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Edit</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please edit your title and message
            </DialogContentText>
              <TextField
                autoFocus
                name="title"
                margin="dense"
                value={title}
                onChange={e => handleEditChange(e)}
                id="name"
                label="Title"
                type="text"
                fullWidth
              />
              <TextField
                autoFocus
                name="message"
                margin="dense"
                multiline
                value={message}
                onChange={e => handleEditChange(e)}
                id="name"
                label="Message"
                type="text"
                fullWidth
                multiline
                rows={5}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
            </Button>
              <Button onClick={handleEdit} color="primary">
                Update
            </Button>
            </DialogActions>
          </Dialog>
          <Popover
            id={user.UserID}
            open={popover}
            onClose={() => setPopover(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div style={{ padding: 15 }}>
              <Button style={{ marginRight: 10, borderColor: 'green' }} variant="outlined" onClick={windowReload}><span style={{ color: 'green' }}>Success. Reload the page to see updates</span></Button>
              <Button variant="outlined" style={{ borderColor: 'red' }} onClick={() => setPopover(false)}><span style={{ color: 'red' }}>Cancel</span></Button></div>
          </Popover>

        </CardContent>

      </div>
    </Card>
  );
}

LibraryCard.propTypes = {
  className: PropTypes.string,
  post: PropTypes.object.isRequired,
  comments: PropTypes.array,
  onCommentCompleted: PropTypes.func,
  onOpenImage: PropTypes.func,
  onDeleteMessageCompleted: PropTypes.func
};

export default LibraryCard;
