import uuid from 'uuid/v1';
import moment from 'moment';
import mock from 'src/utils/mock';
import { colors } from '@material-ui/core';


mock.onGet('/api/outgoing/referrals').reply(200, {
  referrals: [
    {
      id: uuid(),
      created_at: moment().subtract(230, 'minutes'),
      updated_at: moment().subtract(23, 'minutes'),
      partner:
      {
        id: 123,
        name: 'BCA Group',
        industry: 'accounting',
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        avatar: '/images/avatars/avatar_1.png',
        bio: 'We are Australia #1 accounting firm'
      },
      referred_entity:
      {
        id: 123,
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        contact_email: 'joestar@Palse.com.au',
        contact_phone: '0401123456',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        company: 'Palse',
        industry: 'Fintech',
      },
        status: 'New', // Values -  'New','Viewed', 'Accepted', 'Declined', 'Customer Contacted', 'Customer Declined', 'Deal Closed'

      opportunity: 
      {
        deadline: moment().add(7, 'days'),
        budget: '12,500',
        currency: 'AUD',
        services: [
          {
            text: 'R&D tax',
            color: colors.green[600]
          },
          {
            text: 'Financial planning',
            color: colors.green[600]
          }
        ]
      },
      commission:
      {
        value: '10% of profits',
        type: 'Gift Card'
      },
      date_created: moment().add(-1, 'days')
    },
    {
      id: uuid(),
      created_at: moment().subtract(230, 'minutes'),
      updated_at: moment().subtract(23, 'minutes'),
      partner:
      {
        id: 123,
        name: 'BCA Group',
        industry: 'accounting',
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        avatar: '/images/avatars/avatar_2.png',
        bio: 'We are Australia #1 accounting firm'
      },
      referred_entity:
      {
        id: 123,
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        contact_email: 'joestar@Palse.com.au',
        contact_phone: '0401123456',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        company: 'Palse',
        industry: 'Fintech',
      },
      status: 'Accepted', // Values -  'New','Viewed', 'Accepted', 'Declined', 'Customer Contacted', 'Customer Declined', 'Deal Closed'
      opportunity: 
      {
        deadline: moment().add(7, 'days'),
        budget: '12,500',
        currency: 'AUD',
        services: [
         {
            text: 'Financial planning',
            color: colors.green[600]
          }
        ]
      },
      commission:
      {
        value: '10% of profits',
        type: 'Gift Card'
      },
      date_created: moment().add(-1, 'days')
    },
    {
      id: uuid(),
      created_at: moment().subtract(230, 'minutes'),
      updated_at: moment().subtract(23, 'minutes'),
      partner:
      {
        id: 123,
        name: 'BCA Group',
        industry: 'accounting',
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        avatar: '/images/avatars/avatar_3.png',
        bio: 'We are Australia #1 accounting firm'
      },
      referred_entity:
      {
        id: 123,
        contact_first_name: 'John',
        contact_last_name: 'Smith',
        contact_email: 'joestar@Palse.com.au',
        contact_phone: '0401123456',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        company: 'Palse',
        industry: 'Fintech',
      },
        status: 'New',// Values -  'New','Viewed', 'Accepted', 'Declined', 'Customer Contacted', 'Customer Declined', 'Deal Closed'
      opportunity: 
      {
        deadline: moment().add(7, 'days'),
        budget: '12,500',
        currency: 'AUD',
        services: [
          {
            text: 'R&D tax',
            color: colors.green[600]
          },
          {
            text: 'Financial planning',
            color: colors.green[600]
          }
        ]
      },
      commission:
      {
        value: '10% of profits',
        type: 'Gift Card'
      },
      date_created: moment().add(-1, 'days')
    },
    {
      id: uuid(),
      created_at: moment().subtract(230, 'minutes'),
      updated_at: moment().subtract(23, 'minutes'),
      partner:
      {
        id: 123,
        name: 'BCA Group',
        industry: 'accounting',
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        avatar: '/images/avatars/avatar_4.png',
        bio: 'We are Australia #1 accounting firm'
      },
      referred_entity:
      {
        id: 123,
        contact_first_name: 'John',
        contact_last_name: 'Smith',
        contact_email: 'joestar@Palse.com.au',
        contact_phone: '0401123456',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        company: 'Palse',
        industry: 'Fintech',
      },
        status: 'Declined', // Values -  'New','Viewed', 'Accepted', 'Declined', 'Customer Contacted', 'Customer Declined', 'Deal Closed'
      opportunity: 
      {
        deadline: moment().add(7, 'days'),
        budget: '12,500',
        currency: 'AUD',
        services: [
          {
            text: 'R&D tax',
            color: colors.green[600]
          },
          {
            text: 'Financial planning',
            color: colors.green[600]
          }
        ]
      },
      commission:
      {
        value: '10% of profits',
        type: 'Gift Card'
      },
      date_created: moment().add(-1, 'days')
    },
    {
      id: uuid(),
      created_at: moment().subtract(230, 'minutes'),
      updated_at: moment().subtract(23, 'minutes'),
      partner:
      {
        id: 123,
        name: 'BCA Group',
        industry: 'accounting',
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        avatar: '/images/avatars/avatar_5.png',
        bio: 'We are Australia #1 accounting firm'
      },
      referred_entity:
      {
        id: 123,
        contact_first_name: 'John',
        contact_last_name: 'Smith',
        contact_email: 'joestar@Palse.com.au',
        contact_phone: '0401123456',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        company: 'Palse',
        industry: 'Fintech',
      },
        status: 'Deal Closed', // Values -  'New','Viewed', 'Accepted', 'Declined', 'Customer Contacted', 'Customer Declined', 'Deal Closed'
      opportunity: 
      {
        deadline: moment().add(7, 'days'),
        budget: '12,500',
        currency: 'AUD',
        services: [
          {
            text: 'R&D tax',
            color: colors.green[600]
          },
          {
            text: 'Financial planning',
            color: colors.green[600]
          }
        ]
      },
      commission:
      {
        value: '10% of profits',
        type: 'Gift Card'
      },
      date_created: moment().add(-1, 'days')
    },
    {
      id: uuid(),
      created_at: moment().subtract(230, 'minutes'),
      updated_at: moment().subtract(23, 'minutes'),
      partner:
      {
        id: 123,
        name: 'BCA Group',
        industry: 'accounting',
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        avatar: '/images/avatars/avatar_6.png',
        bio: 'We are Australia #1 accounting firm'
      },
      referred_entity:
      {
        id: 123,
        contact_first_name: 'John',
        contact_last_name: 'Smith',
        contact_email: 'joestar@Palse.com.au',
        contact_phone: '0401123456',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        company: 'Palse',
        industry: 'Fintech',
      },
        status: 'Customer Contacted', // Values -  'New','Viewed', 'Accepted', 'Declined', 'Customer Contacted', 'Customer Declined', 'Deal Closed'
      opportunity: 
      {
        deadline: moment().add(7, 'days'),
        budget: '12,500',
        currency: 'AUD',
        services: [
          {
            text: 'R&D tax',
            color: colors.green[600]
          },
          {
            text: 'Financial planning',
            color: colors.green[600]
          }
        ]
      },
      commission:
      {
        value: '10% of profits',
        type: 'Gift Card'
      },
      date_created: moment().add(-1, 'days')
    },
    {
      id: uuid(),
      created_at: moment().subtract(230, 'minutes'),
      updated_at: moment().subtract(23, 'minutes'),
      partner:
      {
        id: 123,
        name: 'BCA Group',
        industry: 'accounting',
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        avatar: '/images/avatars/avatar_3.png',
        bio: 'We are Australia #1 accounting firm'
      },
      referred_entity:
      {
        id: 123,
        contact_first_name: 'John',
        contact_last_name: 'Smith',
        contact_email: 'joestar@Palse.com.au',
        contact_phone: '0401123456',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        company: 'Palse',
        industry: 'Fintech',
      },
        status: 'New', // Values -  'New','Viewed', 'Accepted', 'Declined', 'Customer Contacted', 'Customer Declined', 'Deal Closed'
      opportunity: 
      {
        deadline: moment().add(7, 'days'),
        budget: '12,500',
        currency: 'AUD',
        services: [
          {
            text: 'R&D tax',
            color: colors.green[600]
          },
          {
            text: 'Financial planning',
            color: colors.green[600]
          }
        ]
      },
      commission:
      {
        value: '10% of profits',
        type: 'Gift Card'
      },
      date_created: moment().add(-1, 'days')
    },
    {
      id: uuid(),
      created_at: moment().subtract(230, 'minutes'),
      updated_at: moment().subtract(23, 'minutes'),
      partner:
      {
        id: 123,
        name: 'BCA Group',
        industry: 'accounting',
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        avatar: '/images/avatars/avatar_3.png',
        bio: 'We are Australia #1 accounting firm'
      },
      referred_entity:
      {
        id: 123,
        contact_first_name: 'John',
        contact_last_name: 'Smith',
        contact_email: 'joestar@Palse.com.au',
        contact_phone: '0401123456',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        company: 'Palse',
        industry: 'Fintech',
      },
        status: 'Deal Closed', // Values -  'New','Viewed', 'Accepted', 'Declined', 'Customer Contacted', 'Customer Declined', 'Deal Closed'
      opportunity: 
      {
        deadline: moment().add(7, 'days'),
        budget: '12,500',
        currency: 'AUD',
        services: [
          {
            text: 'R&D tax',
            color: colors.green[600]
          },
          {
            text: 'Financial planning',
            color: colors.green[600]
          }
        ]
      },
      commission:
      {
        value: '10% of profits',
        type: 'Gift Card'
      },
      date_created: moment().add(-1, 'days')
    },
    {
      id: uuid(),
      created_at: moment().subtract(230, 'minutes'),
      updated_at: moment().subtract(23, 'minutes'),
      partner:
      {
        id: 123,
        name: 'BCA Group',
        industry: 'accounting',
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        avatar: '/images/avatars/avatar_3.png',
        bio: 'We are Australia #1 accounting firm'
      },
      referred_entity:
      {
        id: 123,
        contact_first_name: 'John',
        contact_last_name: 'Smith',
        contact_email: 'joestar@Palse.com.au',
        contact_phone: '0401123456',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        company: 'Palse',
        industry: 'Fintech',
      },
        status: 'New', // Values -  'New','Viewed', 'Accepted', 'Declined', 'Customer Contacted', 'Customer Declined', 'Deal Closed'
      opportunity: 
      {
        deadline: moment().add(7, 'days'),
        budget: '12,500',
        currency: 'AUD',
        services: [
          {
            text: 'R&D tax',
            color: colors.green[600]
          },
          {
            text: 'Financial planning',
            color: colors.green[600]
          }
        ]
      },
      commission:
      {
        value: '10% of profits',
        type: 'Gift Card'
      },
      date_created: moment().add(-1, 'days')
    },
    {
      id: uuid(),
      created_at: moment().subtract(230, 'minutes'),
      updated_at: moment().subtract(23, 'minutes'),
      partner:
      {
        id: 123,
        name: 'BCA Group',
        industry: 'accounting',
        contact_first_name: 'Joe',
        contact_last_name: 'Star',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        avatar: '/images/avatars/avatar_3.png',
        bio: 'We are Australia #1 accounting firm'
      },
      referred_entity:
      {
        id: 123,
        contact_first_name: 'John',
        contact_last_name: 'Smith',
        contact_email: 'joestar@Palse.com.au',
        contact_phone: '0401123456',
        contact_position: 'CEO',
        contact_address: '222 City Street, Sydney NSW',
        contact_linkedin: 'www.linkedin.com/in/sivan-atad',
        company: 'Palse',
        industry: 'Fintech',
      },
        status: 'New', // Values -  'New','Viewed', 'Accepted', 'Declined', 'Customer Contacted', 'Customer Declined', 'Deal Closed'
      opportunity: 
      {
        deadline: moment().add(7, 'days'),
        budget: '12,500',
        currency: 'AUD',
        services: [
          {
            text: 'R&D tax',
            color: colors.green[600]
          },
          {
            text: 'Financial planning',
            color: colors.green[600]
          }
        ]
      },
      commission:
      {
        value: '10% of profits',
        type: 'Gift Card'
      },
      date_created: moment().add(-1, 'days')
    }
  ]
});

mock.onGet('/api/outgoing/referral/1').reply(200, {
  referral: {
    id: uuid(),
    status: 'Deal Closed', // Values -  'New','Viewed', 'Accepted', 'Declined', 'Customer Contacted', 'Customer Declined', 'Deal Closed'
    created_at: moment().subtract(230, 'minutes'),
    updated_at: moment().subtract(23, 'minutes'),
    partner:
    {
      id: 123,
      name: 'BCA Group',
      industry: 'accounting',
      contact_first_name: 'Joe',
      contact_last_name: 'Star',
      contact_position: 'CEO',
      contact_address: '222 City Street, Sydney NSW',
      contact_linkedin: 'www.linkedin.com/in/sivan-atad',
      avatar: '/images/avatars/avatar_3.png',
      bio: 'We are Australia #1 accounting firm'
  },
    referred_entity:
    {
      id: 123,
      contact_first_name: 'John',
      contact_last_name: 'Smith',
      contact_email: 'joestar@Palse.com.au',
      contact_phone: '0401123456',
      contact_position: 'CEO',
      contact_address: '222 City Street, Sydney NSW',
      contact_linkedin: 'www.linkedin.com/in/sivan-atad',
      company: 'Palse',
      industry: 'Fintech',
  },
    opportunity: 
    {
      deadline: moment().add(7, 'days'),
      budget: '12,500',
      currency: 'AUD',
      services: [
        {
          text: 'Business accounting',
          color: colors.green[600]
        },
        {
          text: 'R&D tax',
          color: colors.green[600]
        },
        {
          text: 'Financial planning',
          color: colors.green[600]
        }
      ]
    },
    commission:
    {
      value: '10% of profits',
      type: 'Gift Card'
    },
    description: `

The client is looking for a retainer based accounting service for their Fintech startup.

#### Background information

Mojo is a Sydney based Fintech startup founded in 2012. They received $3M investment and are looking to scale their business.

There's not many screens we need designed, but there will be modals and various other system triggered evenets that will need to be considered.

The referral has benn created in Sketch so let me know if there are any problmes opening this referral and I'll try to convert into a usable file.

I have attached a chat with our users most used devices.

#### Goals:
  - R&D tax incentives
  - Bookeeping
  - Accounting
    `,
    files: [
      {
        id: uuid(),
        name: 'example-project1.jpg',
        url: '/images/projects/project_2.jpg',
        mimeType: 'image/png',
        size: 1024 * 1024 * 3
      },
      {
        id: uuid(),
        name: 'docs.zip',
        url: '#',
        mimeType: 'application/zip',
        size: 1024 * 1024 * 25
      },
      {
        id: uuid(),
        name: 'example-project2.jpg',
        url: '/images/projects/project_1.jpg',
        mimeType: 'image/png',
        size: 1024 * 1024 * 2
      }
    ],
    activities: [
      {
        id: uuid(),
        subject: 'Project owner',
        subject_type: 'user',
        action_type: 'upload_file',
        action_desc: 'has uploaded a new file',
        created_at: moment().subtract(23, 'minutes')
      },
      {
        id: uuid(),
        subject: 'Adrian Stefan',
        subject_type: 'user',
        action_type: 'join_team',
        action_desc: 'joined team as a Front-End Developer',
        created_at: moment().subtract(2, 'hours')
      },
      {
        id: uuid(),
        subject: 'Alexandru Robert',
        action_type: 'join_team',
        action_desc: 'joined team as a Full Stack Developer',
        created_at: moment().subtract(9, 'hours')
      },
      {
        id: uuid(),
        subject: 'Project owner',
        subject_type: 'user',
        action_type: 'price_change',
        action_desc: 'raised the project budget',
        created_at: moment().subtract(2, 'days')
      },
      {
        id: uuid(),
        subject: 'Contest',
        subject_type: 'project',
        action_type: 'contest_created',
        action_desc: 'created',
        created_at: moment().subtract(4, 'days')
      }
    ],
  }
});