import React from 'react'
import ActivityProgress from './ActivityProgress'
import PromotedContent from './PromotedContent'
import { makeStyles } from '@material-ui/core'
// import SharingInfo from 'src/componentsnew/SharingInfo';
import { useSelector } from 'react-redux';
// import NotificationsBox from './NotificationsBox'


// const useStyles = makeStyles({
const useStyles = makeStyles(theme => ({
    //     shareInfo: {
    //     marginTop: theme.spacing(3)
    //   },
    activityProgress: {
        marginBottom: theme.spacing(8),
    },
    promotedContent: {
        // marginTop: theme.spacing(20),
    },
    shareInfo: {
        marginTop: theme.spacing(2),
    },
    SidebarRight: {
        position: 'fixed',
        marginTop: 60,
        paddingLeft: '60px',
        width: '22rem',
        right: '10px',
        minWidth: '15rem',
        maxWidth: '100%',
        height: '100%',
        overflowY: 'auto',
        padding: 10,
        ['@media (max-width:1280px)']: {
            display: 'none'
        },
    }
}));


function SidebarRight({ unreadCount }) {
    const classes = useStyles()
    // const session = useSelector(state => state.session);

    
    return (
        <div className={classes.SidebarRight}>
            <ActivityProgress className={classes.activityProgress} unreadCount={unreadCount} />

            <PromotedContent className={classes.promotedContent} />

            {/* <Grid item lg={4} md={12} xl={4} xs={12}> */}
            {/* {(localStorage.getItem('TenantDetail') &&
                (JSON.parse(localStorage.getItem('TenantDetail'))[
                'external_registration_link'
                ] ||
                ( JSON.parse(localStorage.getItem('TenantDetail'))['allow_user_invites'] === 'Yes' && session.user.companyProfileUniqueIdentifier)
            )) && (
                    <SharingInfo
                        className={classes.shareInfo}
                        companyProfileUniqueIdentifier={session.user.companyProfileUniqueIdentifier}

                    //   addAnchorEl={addAnchorEl}
                    />
                )} */}
            {/* </Grid> */}
        </div>
    )
}

export default SidebarRight
