import { combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import kanbanReducer from './kanbanReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  kanban: kanbanReducer,

});

export default rootReducer;
