// COPY of template CustomerManagementList
import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { hotjar } from 'react-hotjar';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { theme, themeWithRtl } from './theme';
import { configureStore } from './store';
import routes from './routes';
import GoogleAnalytics from './components/GoogleAnalytics';
import CookiesNotification from './components/CookiesNotification';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
// import DirectionToggle from './components/DirectionToggle';
import { SnackbarProvider } from 'notistack';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
// import './mock';
import './mocknew';
import './assets/scss/main.scss';

const history = createBrowserHistory();
const store = configureStore();

// console.log(`THE HOTJAR_MODE: ${process.env.REACT_APP_HOTJAR_MODE}`);
if (process.env.REACT_APP_HOTJAR_MODE === 'true') {
  hotjar.initialize(1751637, 6);
  console.log(`THE ENVIRONMENT: ${process.env.NODE_ENV}`);
}
else {
  console.log(`THE ENVIRONMENT HOTJAR NOT STARTED: ${process.env.NODE_ENV}`);
}

function App() {
  const [direction, setDirection] = useState('auto');
  const [domain, setDomain] = useState('');
  const handleDirecitonToggle = () => {
    setDirection((prevDirection) => (prevDirection === 'ltr' ? 'rtl' : 'ltr'));
  };



  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={direction === 'rtl' ? themeWithRtl : theme}>
        <StylesProvider direction={direction}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider maxSnack={1}>
              <Router history={history}>
                <ScrollReset />
                <GoogleAnalytics />
                <CookiesNotification />
                {/* <DirectionToggle
                direction={direction}
                onToggle={handleDirecitonToggle}
              /> */}
                {renderRoutes(routes)}
              </Router>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
