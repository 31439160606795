/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import PublicLayout from './layouts/Public';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import PublicProfileLayout from './layouts/PublicProfile';
// import DashboardAnalyticsView from './views/DashboardAnalytics';
// import DashboardDefaultView from './views/DashboardDefault';
// import OverviewView from './views/Overview';
// import PresentationView from './views/Presentation';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/marketplace" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/viewsnew/Login'))
      },
      {
        path: '/auth/register/:id',
        exact: true,
        component: lazy(() => import('src/viewsnew/Register'))
      },
      {
        path: '/auth/invite/:id',
        exact: true,
        component: lazy(() => import('src/viewsnew/Invite'))
      },
      // {
      //   path: '/auth/register',
      //   exact: true,
      //   component: lazy(() => import('src/viewsnew/Register'))
      // },
      {
        path: '/auth/reset',
        exact: true,
        component: lazy(() => import('src/viewsnew/PasswordReset'))
      },
      {
        path: '/auth/update/:resetToken',
        exact: true,
        component: lazy(() => import('src/viewsnew/PasswordUpdate'))
      },
      {
        path: '/auth/joinfrominvite/:resetToken',
        exact: true,
        component: lazy(() => import('src/viewsnew/JoinFromInvite'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/public',
    component: PublicLayout,
    routes: [
      {
        path: '/public/marketplace',
        exact: true,
        component: lazy(() => import('src/viewsnew/PublicMarketplace'))
      },
      {
        path: '/public/profile/:id/',
        exact: true,
        component: lazy(() => import('src/viewsnew/PublicProfile'))
      },
      {
        path: '/public/contact/:id/',
        exact: true,
        component: lazy(() => import('src/viewsnew/PublicPartnerContact'))
      },
      {
        path: '/public/about',
        exact: true,
        component: lazy(() => import('src/viewsnew/About'))
      },
      {
        path: '/public/community-rules',
        exact: true,
        component: lazy(() => import('src/viewsnew/CommunityRules'))
      },
      {
        path: '/public/getting-started',
        exact: true,
        component: lazy(() => import('src/viewsnew/GettingStarted'))
      },
      {
        path: '/public/contactus',
        exact: true,
        component: lazy(() => import('src/viewsnew/ContactUs'))
      },
      {
        path: '/public/privacy',
        exact: true,
        component: lazy(() => import('src/viewsnew/Privacy'))
      },
      {
        path: '/public/terms',
        exact: true,
        component: lazy(() => import('src/viewsnew/Terms'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/p',
    component: PublicProfileLayout,
    routes: [
      {
        path: '/p/:id/',
        exact: true,
        component: lazy(() => import('src/viewsnew/PublicProfile'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/c',
    component: PublicProfileLayout,
    routes: [
      {
        path: '/c/:id/',
        exact: true,
        component: lazy(() => import('src/viewsnew/PublicPartnerContact'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      // OUTGOING PAGES
      {
        path: '/outgoing/referrals',
        exact: true,
        component: () => <Redirect to="/referrals/outbound" />
        // component: lazy(() => import('src/viewsnew/outgoing/ReferralList'))
      },
      {
        path: '/outgoing/createreferral',
        exact: true,
        component: lazy(() => import('src/viewsnew/outgoing/CreateReferral'))
      },
      {
        path: '/outgoing/logreferral',
        exact: true,
        component: lazy(() => import('src/viewsnew/outgoing/LogReferral'))
      },
      {
        path: '/outgoing/logreferral/:partnerid/',
        exact: true,
        component: lazy(() => import('src/viewsnew/outgoing/LogReferral'))
      },
      {
        path: '/outgoing/createreferral/:partnerid/',
        exact: true,
        component: lazy(() => import('src/viewsnew/outgoing/CreateReferral'))
      },
      // {
      //   path: '/outgoing/dashboard',
      //   exact: true,
      //   component: lazy(() => import('src/viewsnew/outgoing/Dashboard'))
      // },
      {
        path: '/marketplace',
        exact: true,
        component: lazy(() => import('src/viewsnew2/Homepage'))
      },
      {
        path: '/marketplace/:tabs',
        exact: true,
        component: lazy(() => import('src/viewsnew2/Homepage'))
      },
      {
        path: '/marketplace/resources/:id',
        exact: true,
        component: lazy(() => import('src/viewsnew2/LibraryComponent'))
      },
      {
      path: '/marketplace/events/:id',
        exact: true,
        component: lazy(() => import('src/viewsnew2/EventComponent'))
      },
      {
        path: '/marketplace/:tabs/:filterCom',
        exact: true,
        component: lazy(() => import('src/viewsnew2/Homepage'))
      },
      {
        path: '/post/:id',
        exact: true,
        component: lazy(() => import('src/viewsnew2/FeedPost'))
      },
      {
        path: '/members',
        exact: true,
        component: lazy(() => import('src/viewsnew2/Members'))
      },
      {
        path: '/outgoing/marketplace',
        exact: true,
        component: lazy(() => import('src/viewsnew/Marketplace'))
      },
      {
        path: '/outgoing/partners',
        exact: true,
        component: () => <Redirect to="/partners/outbound" />
        // component: lazy(() => import('src/viewsnew/outgoing/PartnerList'))
      },
      {
        path: '/outgoing/invitepartner',
        exact: true,
        component: lazy(() => import('src/viewsnew/outgoing/InvitePartner'))
      },
      {
        path: '/outgoing/referrals/:id/',
        exact: true,
        component: lazy(() => import('src/viewsnew/outgoing/ReferralDetails'))
      },
      {
        path: '/outgoing/referrals/:id/:tab',
        exact: true,
        component: lazy(() => import('src/viewsnew/outgoing/ReferralDetails'))
      },

      // INCOMING PAGES
      {
        path: '/incoming/referrals',
        exact: true,
        component: () => <Redirect to="/referrals/inbound" />
        // component: lazy(() => import('src/viewsnew/incoming/ReferralList'))
      },
      {
        path: '/incoming/dashboard',
        exact: true,
        component: lazy(() => import('src/viewsnew/incoming/Dashboard'))
      },
      {
        path: '/incoming/marketplace',
        exact: true,
        component: lazy(() => import('src/viewsnew/incoming/Marketplace'))
      },
      {
        path: '/incoming/partners',
        exact: true,
        component: () => <Redirect to="/partners/inbound" />
        // component: lazy(() => import('src/viewsnew/incoming/PartnerList'))
      },
      {
        path: '/incoming/invitepartner',
        exact: true,
        component: lazy(() => import('src/viewsnew/incoming/InvitePartner'))
      },
      {
        path: '/incoming/admininvitepartner',
        exact: true,
        component: lazy(() => import('src/viewsnew/EcosystemAdminInvitePartner'))
      },
      {
        path: '/incoming/referrals/:id/',
        exact: true,
        component: lazy(() => import('src/viewsnew/incoming/ReferralDetails'))
      },
      {
        path: '/incoming/referrals/:id/:tab',
        exact: true,
        component: lazy(() => import('src/viewsnew/incoming/ReferralDetails'))
      },
      // Main pages
      {
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('src/viewsnew/Dashboard'))
      },
      {
        path: '/board',
        exact: true,
        component: lazy(() => import('src/viewsnew/ProjectsBoard'))
      },
      {
        path: '/profile',
        exact: true,
        component: lazy(() => import('src/viewsnew/Profile'))
      },
      {
        path: '/profile/:tab',
        exact: true,
        component: lazy(() => import('src/viewsnew/Profile'))
      },
      {
        path: '/notifications',
        exact: true,
        component: lazy(() => import('src/viewsnew/Profile/Notifications'))
      },
      {
        path: '/meetingclub',
        exact: true,
        component: lazy(() => import('src/viewsnew/MeetingClub'))
      },
      {
        path: '/meetingclubfeedback/:id?',
        exact: true,
        component: lazy(() => import('src/viewsnew/MeetingClubFeedback'))
      },
      {
        path: '/meetingclubadmin',
        exact: true,
        component: lazy(() => import('src/viewsnew/MeetingClubAdmin'))
      },
      {
        path: '/partners/:tab',
        exact: true,
        component: lazy(() => import('src/viewsnew/Partners'))
      },
      {
        path: '/partner/:id',
        exact: true,
        component: lazy(() => import('src/viewsnew/Partner'))
      },
      {
        path: '/partner/:id/:tab',
        exact: true,
        component: lazy(() => import('src/viewsnew/Partner'))
      },
      {
        path: '/referrals/:tab',
        exact: true,
        component: lazy(() => import('src/viewsnew/Referrals'))
      },
      {
        path: '/about',
        exact: true,
        component: lazy(() => import('src/viewsnew/About'))
      },
      {
        path: '/community-rules',
        exact: true,
        component: lazy(() => import('src/viewsnew/CommunityRules'))
      },
      {
        path: '/getting-started',
        exact: true,
        component: lazy(() => import('src/viewsnew/GettingStarted'))
      },
      {
        path: '/contactus',
        exact: true,
        component: lazy(() => import('src/viewsnew/ContactUs'))
      },
      {
        path: '/chat/:id?',
        exact: true,
        component: lazy(() => import('src/viewsnew/Chat'))
      },
      {
        path: '/library',
        exact: true,
        component: lazy(() => import('src/viewsnew/Library'))
      },
      {
        path: '/feed',
        exact: true,
        component: lazy(() => import('src/viewsnew/Feed'))
      },
      {
        path: '/events',
        exact: true,
        component: lazy(() => import('src/viewsnew/Events'))
      },
      {
        path: '/my-projects',
        exact: true,
        component: lazy(() => import('src/viewsnew/MyProjects'))
      },
      {
        path: '/project/:id',
        exact: true,
        component: lazy(() => import('src/viewsnew/Project'))
      },
      {
        path: '/project/:id/:tab',
        exact: true,
        component: lazy(() => import('src/viewsnew/Project'))
      },
      {
        path: '/ecosystem-admin/:tab',
        exact: true,
        component: lazy(() => import('src/viewsnew/EcosystemAdmin'))
      },
      // {
      //   path: '/kanban',
      //   exact: true,
      //   component: lazy(() => import('src/views/KanbanBoard'))
      // },
      {
        component: () => <Redirect to="/errors/error-404" />
      },
    ]
  }
];
