import React, { useEffect, useState  } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setTenantDetail } from 'src/actions';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  toolbar: {
    minHeight: 40,
  },
  logo: {
    height: 33,
  },
  logoText: {
    color: '#FFFFFF'
  }
}));

function Topbar({ className, ...rest }) {
  const classes = useStyles();
  const session = useSelector((state) => state.session);

  // const [tenantDetail, setTenantDetails] = useState(
  //   localStorage.getItem("TenantDetail")?JSON.parse(localStorage.getItem("TenantDetail")):{}  );  const dispatch = useDispatch();
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = false;
    let tenantDetailExists = true;

    if (!mounted) {

      if(!localStorage.getItem("TenantDetail")) {
        tenantDetailExists = false;
      }      

      let subDomain =window.location.hostname.replace('.bizforce.io','');
      axios.get(`${process.env.REACT_APP_API_URL}api/ecosystem/GetEcosystemDetails/`+subDomain)
      .then((res)=>{
        if(res!=null && res.data!==null && res.data.length>0){
          var tenantInfo = res.data[0];
          tenantInfo.subdomain = subDomain;
          localStorage.setItem("TenantDetail", JSON.stringify( res.data[0]));
          // setTenantDetails(res.data[0]);

          const tenantDetailRes = res.data[0];
          dispatch(setTenantDetail(tenantDetailRes));

          if(!tenantDetailExists) {
            window.location.reload();
          }      
    
        }
      });
      mounted = true;

    }

  }, []);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      style={{backgroundColor: session.primaryColor}}
    >
      <Toolbar
        className={classes.toolbar}
      >
        {/* <RouterLink to="/"> */}
        {/* <Typography className={classes.logoText}
          variant="h2"
        >
            BizForce
          </Typography> */}
          <img
            alt="Logo"
            // width={175}
            className={classes.logo}
            alt={session.tenantDetail.name}
            src={session.tenantDetail.logo}
            // src="/images/logos/bizforce-logo-white.svg"
          />
        {/* </RouterLink> */}
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
