import React, { useState, useEffect } from 'react'
import Members from './Members'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles';
import {
  Divider, Typography
} from '@material-ui/core';
// import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  addRooms: {
    display: 'flex',
    // alignItems: 'right',
    justifyContent: 'flex-end',
    borderRadius: 10,
    // paddingLeft: 50,
    marginTop: 10,
    '&:hover': {
      backgroundColor: '#80808059',
    }
  },
  btnText: {
    fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    color: '#808091',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  recentlyActive: {
    backgroundColor:   '#FFFFFF',
    padding: 10,
    boxShadow: `0px 4px 10px rgba(34, 36, 36, 0.30)`,
    color: '#808091',
    marginBottom: 10,
    borderRadius: 10,
    
    fontFamily: 'Gilroy',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',

}
}));

function Index() {
  const classes = useStyles();
  const axios = require('axios');
  const params = new URLSearchParams();
  const jwtToken = localStorage.getItem('jwtToken') || '';
  const history = useHistory();
  const [isWaiting, setIsWaiting] = useState(true);
  const [isWaitingRecent, setIsWaitingRecent] = useState(true);
  const [loading, setLoading] = useState(true);
  const [
    companyProfilesRecentlyActive,
    setCompanyProfilesRecentlyActive
  ] = useState([]);

  useEffect(() => {
    let mounted = true
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}api/senderPartner/GetCompanyProfilesRecentlyActive`,
      headers: {
        Authorization: 'Bearer ' + jwtToken
      },
      data: params
    }).then(
      response => {
        if (mounted) {
          setCompanyProfilesRecentlyActive(response.data);
          setIsWaitingRecent(false);
          setLoading(false)
        }
      },
      error => {
        console.log('fetchCompanyProfilesRecentlyActive error');
        console.log(error);
        setIsWaitingRecent(false);
        // localStorage.setItem('postLoginPath', history.location.pathname);
        // error.response != null && error.response.status === 401
        //   ? history.push('/auth/login')
        //   : window.alert(
        //     'Oooops... An error has occured. Please contact support@bizforce.io. ' +
        //     error
        //   );
      }
    );

  },[])

  const fiterredRecentlyActive = companyProfilesRecentlyActive?.filter((data, index) => {
    if (index <= 2) {
      return data
    }
  })


  return (
    <div style={{
      padding: 10,
      marginTop: 15,
    }}>
      <div className={classes.recentlyActive}>Recently Active</div>
      {loading ?
        <div>Loading... </div>
        :
        <div> 
          {fiterredRecentlyActive?.map(recentlyActive => (
            <Members  key={recentlyActive.id} members={recentlyActive} />
          ))}
          </div>
      }
      <Typography
        className={classes.addRooms}
        component={RouterLink}
        to={'/marketplace/members'}
      >
        <h5 className={classes.btnText}>View more...</h5>
      </Typography>

      <Divider className={classes.divider} />
    </div>
  )
}

export default Index
